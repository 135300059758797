import { PAGES } from "constants/pages";
import { useTranslation } from "react-i18next";

export const getBreadcrumbs = (location, i18n) => {
  const locationArray = location?.split("/").slice(1);
  const breadcrumbs = new Array(0);
  let pagesObject = PAGES;
  let pagesProperties = Object.keys(pagesObject);
  while (breadcrumbs?.length !== locationArray?.length) {
    let routeObject =
      pagesObject?.[
        pagesProperties?.find?.(
          (singlePageProperty) =>
            getPageNameFromRoute(pagesObject?.[singlePageProperty]?.route) ===
            locationArray?.[breadcrumbs?.length]
        )
      ];

    breadcrumbs?.push({
      title: i18n.t(routeObject?.titleKey),
    });

    pagesObject = routeObject?.nestedRoutes;
    pagesProperties = pagesObject != null && Object.keys(pagesObject);
  }
  return breadcrumbs;
};

export const generateBreadcrumbsFromPath = (pathname) => {
  const segments = pathname.split("/").filter(Boolean);
  const breadcrumbs = [];
  const { t } = useTranslation();

  segments.reduce((accPath, segment) => {
    accPath = `${accPath}/${segment}`;
    let matchedKey;
    let pagesObject = PAGES;

    for (const key of Object.keys(pagesObject)) {
      const route = pagesObject[key].route;
      const routeSegments = route.split("/").filter(Boolean);
      const currentPathSegments = accPath.split("/").filter(Boolean);

      const isMatch = currentPathSegments.every(
        (pathSegment, index) =>
          routeSegments[index] === pathSegment ||
          routeSegments[index]?.startsWith(":")
      );

      if (isMatch) {
        matchedKey = key;
        const page = pagesObject[matchedKey];
        const i18nKey = `${matchedKey}`;
        const show = Boolean(page.title);

        breadcrumbs.push({
          i18nKey,
          title: t(page.title) || i18nKey,
          titleRenderer: page.titleRenderer,
          titleToRender: page.titleToRender,
          show,
        });
        pagesObject = page.nestedRoutes || {};
        break;
      }
    }

    return accPath;
  }, "");

  let helperArray = {};
  return breadcrumbs.filter((x) => {
    if (helperArray?.[x?.i18nKey]) return false;
    helperArray[x.i18nKey] = x;
    return true;
  });
};

const getPageNameFromRoute = (route) => {
  let routeArray = route?.split("/");
  if (routeArray?.[0] === "") return routeArray?.[1];
  else return routeArray?.[0];
};

export const getCurrentRoute = (path) => {
  let currentPage = Object.keys(PAGES).find((singleRoute) => {
    return compareRoutes(path, PAGES?.[singleRoute]?.route);
  });
  return PAGES?.[currentPage];
};

export const compareRoutes = (firstPath, secondPath) => {
  let firstPathArray = firstPath?.split?.("/");
  let secondPathArray = secondPath?.split?.("/");
  if (firstPathArray?.[firstPathArray?.length - 1] === "*") {
    firstPathArray = firstPathArray?.slice(0, -1);
  }
  if (firstPathArray?.[firstPathArray?.length - 1] === "*")
    firstPathArray = firstPathArray?.slice(0, -1);
  if (firstPathArray.length !== secondPathArray.length) {
    return false;
  }
  return firstPathArray.every((segment, index) => {
    return (
      segment === secondPathArray[index] ||
      secondPathArray[index].startsWith(":")
    );
  });
};

export const isInRoute = (firstPath, secondPath) => {
  let firstPathArray = firstPath?.split?.("/");
  let secondPathArray = secondPath?.split?.("/");
  if (firstPathArray?.[firstPathArray?.length - 1] === "*")
    firstPathArray = firstPathArray?.slice(0, -1);
  if (secondPathArray?.[secondPathArray?.length - 1] === "*")
    secondPathArray = secondPathArray?.slice(0, -1);
  return secondPathArray?.every((singlePath, index) => {
    if (singlePath === firstPathArray?.[index]) return true;
    if (singlePath?.[0] === ":") return true;
    if (singlePath === "*") return true;
    return false;
  });
};

export const replaceInRoute = (route, pathParams = {}) => {
  return Object.entries(pathParams).reduce(
    (acc, [key, value]) => acc.replace(`:${key}`, value),
    route
  );
};
