import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  ContentContainer,
  MainContainer,
  Title,
} from "./ServicesContent.styled";
import { useTranslation } from "react-i18next";
import ServicesTable from "./ServicesTable/ServicesTable";
import FilterContainer from "components/Filter/FilterContainer";
import { randomIdGenerator } from "util/randomGenerator";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import inputTypes from "constants/inputTypes";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { removeFilter } from "util/filterHelper";
import filterValueToRemove from "constants/filters/filterValueToRemove";

const ServicesContent = (props) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const mapServiceCategoriesFunction = (data) =>
    data.data.serviceCategories.map((sc) => {
      return {
        id: sc.id,
        name: sc.name,
      };
    });

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"clientService.serviceName"}
          placeholder={"clientService.placeholderName"}
          onChange={(name) =>
            setFilters((prevState) => ({
              ...prevState,
              name: { id: name, name, valueToRemove: filterValueToRemove.NAME },
            }))
          }
          value={filters?.name?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"clientService.filters.minimalDurationInMinutesLabel"}
          type={inputTypes.NUMBER}
          minNumValue={0}
          onChange={(durationFrom) =>
            setFilters((prevState) => ({
              ...prevState,
              durationFrom: {
                id: durationFrom,
                name: durationFrom,
                valueToRemove: filterValueToRemove.DURATION_FROM,
              },
            }))
          }
          value={filters?.durationFrom?.id}
        />
        <TextFilter
          label={"clientService.filters.maximalDurationInMinutesLabel"}
          type={inputTypes.NUMBER}
          minNumValue={0}
          onChange={(durationTo) =>
            setFilters((prevState) => ({
              ...prevState,
              durationTo: {
                id: durationTo,
                name: durationTo,
                valueToRemove: filterValueToRemove.DURATION_TO,
              },
            }))
          }
          value={filters?.durationTo?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"clientService.filters.minimalPriceLabel"}
          type={inputTypes.NUMBER}
          minNumValue={0}
          onChange={(priceFrom) =>
            setFilters((prevState) => ({
              ...prevState,
              priceFrom: {
                id: priceFrom,
                name: priceFrom,
                valueToRemove: filterValueToRemove.PRICE_FROM,
              },
            }))
          }
          value={filters?.priceFrom?.id}
        />
        <TextFilter
          label={"clientService.filters.maximalPriceLabel"}
          type={inputTypes.NUMBER}
          minNumValue={0}
          onChange={(priceTo) =>
            setFilters((prevState) => ({
              ...prevState,
              priceTo: {
                id: priceTo,
                name: priceTo,
                valueToRemove: filterValueToRemove.PRICE_TO,
              },
            }))
          }
          value={filters?.priceTo?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"common.labelPrimaryService"}
          placeholder={"superAdmin.table.clients.filters.primaryServiceSelect"}
          defaultValue={"superAdmin.table.clients.filters.allServices"}
          onChange={(primaryService) =>
            setFilters((prevState) => ({
              ...prevState,
              primaryService: {
                ...primaryService,
                valueToRemove: filterValueToRemove.PRIMARY_SERVICE,
              },
            }))
          }
          value={filters?.serviceCategoryId}
          mapDataFunction={mapServiceCategoriesFunction}
          useQuery={useGetAllServiceCategoriesQuery}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  return (
    <MainContainer>
      <Title>{t("clientPanel.servicesSegment.title")}</Title>
      <ContentContainer>
        <FilterContainer
          filterComponents={filterComponents}
          saveFilters={saveFilters}
          isFilterButtonShown
          appliedFilters={appliedFilters}
          filters={filters}
          removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
        />
        <ServicesTable
          clientId={props?.client?.id}
          appliedFilters={appliedFilters}
        />
      </ContentContainer>
    </MainContainer>
  );
};

ServicesContent.propTypes = {
  client: PropTypes.any,
};

export default ServicesContent;
