import { userFormikParams } from "constants/entityFormikParams/userFormikParams";

export const userParams = (user) => {
  return {
    [userFormikParams.firstName]: user?.firstName ?? "",
    [userFormikParams.lastName]: user?.lastName ?? "",
    [userFormikParams.email]: user?.email ?? "",
    [userFormikParams.city]: { id: user?.city, name: user?.city } ?? "",
    [userFormikParams.country]:
      { id: user?.country, name: user?.country } ?? "",
    [userFormikParams.address]: user?.address ?? "",
    [userFormikParams.countryPhoneCode]:
      { id: "", name: user?.phoneNumber?.slice(0, 4) } ?? null,
    [userFormikParams.phoneNumber]: user?.phoneNumber?.slice(4) ?? "",
  };
};
