import React, { useEffect, useState } from "react";
import {
  AddLogo,
  ClientLogoStyled,
  ClientLogoWrapper,
  ClientText,
  DeleteButton,
  LogoContainer,
} from "./ClientLogo.styled";
import PropTypes from "prop-types";
import {
  useAddClientLogoMutation,
  useDeleteClientImageMutation,
  useGetClientLogoQuery,
} from "features/clients/clientsApiSlice";
import { ReactComponent as TrashIcon } from "assets/images/svg/trash-icon.svg";
import defaultLogo from "assets/images/svg/default-ClientLogo.png";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { useTranslation } from "react-i18next";
import { useIsLoading } from "hooks/useIsLoading";

const ClientLogo = (props) => {
  const { data, isLoading, isError } = useGetClientLogoQuery({
    id: props?.logoId,
  });
  useIsLoading(isLoading);

  const { t } = useTranslation();

  const [imageUrl, setImageUrl] = useState(null);
  const [hasCustomImage, setCustomImage] = useState(false);
  const [addClientLogo] = useAddClientLogoMutation();
  const [deleteImage] = useDeleteClientImageMutation();

  useEffect(() => {
    if (data && data.imageUrl && !isError) {
      setImageUrl(data.imageUrl);
      setCustomImage(true);
    } else {
      setImageUrl(defaultLogo);
    }

    return () => {
      if (imageUrl && imageUrl !== defaultLogo) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [data]);

  const handleImageDelete = async () => {
    const response = await deleteImage(props?.logoId);

    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("clientService.successfulyDeleted"),
        toastNotificationType.SUCCESS
      );
    }

    setImageUrl(defaultLogo);
    setCustomImage(false);
  };

  const handleImageUpload = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const tempImageUrl = URL.createObjectURL(file);
        setImageUrl(tempImageUrl);

        const formData = new FormData();
        formData.append("File", file);
        formData.append("ClientId", props?.clientId);
        formData.append("isGalleryImage", false);

        const response = await addClientLogo(formData);
        if (response?.error) {
          console.log(response.error);
          makeToastMessage(
            response.error.data.errors[0].message,
            toastNotificationType.ERROR
          );
        } else {
          makeToastMessage(
            t("superAdmin.table.clients.successfullyUpdatedClientLogo"),
            toastNotificationType.SUCCESS
          );
        }
      }
    };
    input.click();
  };

  return (
    <ClientLogoWrapper>
      <LogoContainer hasCustomImage={hasCustomImage}>
        <ClientLogoStyled src={imageUrl} alt="Client Logo" />
        {imageUrl !== defaultLogo && (
          <DeleteButton onClick={handleImageDelete}>
            <TrashIcon />
          </DeleteButton>
        )}
        <AddLogo onClick={handleImageUpload} />
      </LogoContainer>
      <ClientText>{props?.clientName}</ClientText>
    </ClientLogoWrapper>
  );
};

ClientLogo.propTypes = {
  clientName: PropTypes.string,
  logoId: PropTypes.string,
  clientId: PropTypes.string,
};

export default ClientLogo;
