import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoaderCount } from "../store/selectors/loadingSelectors";
import { setLoadingModal, unsetLoadingModal } from "features/modal/modalSlice";

export const useIsLoading = (customIsLoadingIndicator = null) => {
  const loaderCount = useSelector(selectLoaderCount);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customIsLoadingIndicator) {
      dispatch(setLoadingModal());
    } else {
      if (customIsLoadingIndicator != null) {
        dispatch(unsetLoadingModal());
      }
    }
  }, [customIsLoadingIndicator]);

  useEffect(() => {
    if (customIsLoadingIndicator == null) {
      if (loaderCount !== 0) {
        dispatch(setLoadingModal());
      } else {
        dispatch(unsetLoadingModal());
      }
    }
  }, [loaderCount]);
};
