import styled from "styled-components";
import { Box } from "@mui/material";
import { ReactComponent as SingleRightArrowIcon } from "../../assets/images/svg/single-right-arrow.svg";
import Button from "components/Button/Button";

export const ClientEditTabStyled = styled(Box)`
  height: fit-content;
  width: 95%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  border-radius: 5px;
  position: relative;
`;

export const SingleRightArrowIconStyled = styled(SingleRightArrowIcon)`
  align-self: center;
  justify-items: center;
  height: 24px;
  width: 24px;
`;

export const HeaderWrapper = styled(Box)`
  display: flex;
`;

export const SaveButtonWrapper = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
  }

  &.Mui-disabled {
    background-color: ${(props) => props?.theme?.colors?.disabledColor};
  }
  max-height: 33px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 35px;
`;
