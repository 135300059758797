import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";
import React, { useEffect, useMemo, useState } from "react";
import { randomIdGenerator } from "util/randomGenerator";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { getServiceCategory } from "features/serviceCategory/serviceCategorySlice";
import { useSelector } from "react-redux";
import ClientsTable from "components/ClientsContent/ClientsTable";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import { removeFilter } from "util/filterHelper";

export const ClientsPage = () => {
  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const selectedServiceCategory = useSelector(getServiceCategory);

  useEffect(() => {
    if (selectedServiceCategory) {
      setFilters((prevState) => ({
        ...prevState,
        primaryService: {
          id: selectedServiceCategory?.id,
          name: selectedServiceCategory.name,
        },
      }));

      setAppliedFilters((prevState) => ({
        ...prevState,
        serviceCategory: selectedServiceCategory?.id,
      }));
    }
  }, [selectedServiceCategory]);

  const mapCitiesFunction = (data) =>
    data?.data?.cityNames?.map((name) => {
      return {
        id: name,
        name,
      };
    }) || [];

  const mapServiceCategoriesFunction = (data) =>
    data?.data?.serviceCategories?.map((sc) => {
      return {
        id: sc.id,
        name: sc.name,
      };
    }) || [];

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"common.labelClientName"}
          placeholder={"superAdmin.table.clients.filters.clientNameInput"}
          onChange={(name) =>
            setFilters((prevState) => ({
              ...prevState,
              name: { id: name, name, valueToRemove: filterValueToRemove.NAME },
            }))
          }
          value={filters?.name?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"common.labelLocation"}
          placeholder={"superAdmin.table.clients.filters.citySelect"}
          defaultValue={"superAdmin.table.clients.filters.allCities"}
          onChange={(city) =>
            setFilters((prevState) => ({
              ...prevState,
              city: { ...city, valueToRemove: filterValueToRemove.CITY },
            }))
          }
          value={filters?.city}
          mapDataFunction={mapCitiesFunction}
          useQuery={useGetAllCitiesQuery}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"common.labelPrimaryService"}
          placeholder={"superAdmin.table.clients.filters.primaryServiceSelect"}
          defaultValue={"superAdmin.table.clients.filters.allServices"}
          onChange={(primaryService) =>
            setFilters((prevState) => ({
              ...prevState,
              primaryService: {
                ...primaryService,
                valueToRemove: filterValueToRemove.PRIMARY_SERVICE,
              },
            }))
          }
          value={filters?.primaryService}
          mapDataFunction={mapServiceCategoriesFunction}
          useQuery={useGetAllServiceCategoriesQuery}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  const AddFilters = () => {};

  return (
    <MainLayout>
      <PageHeader
        onClick={AddFilters}
        isAddButtonShown={false}
        isFilterButtonShown={true}
        filterComponents={filterComponents}
        appliedFilters={appliedFilters}
        filters={filters}
        removeFilter={(valueToRemove) =>
          removeFilter(valueToRemove, setFilters)
        }
        saveFilters={saveFilters}
        leftIcon={<ControlTableIconSelected />}
      />
      <ClientsTable appliedFilters={appliedFilters} />
    </MainLayout>
  );
};
