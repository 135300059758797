import styled from "styled-components";

export const AppointmentStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 30px;
  background-color: ${({ currentActive, status, theme }) => {
    switch (status) {
      case 0:
        if (currentActive) {
          return theme.colors.statusOrange;
        }
        return theme.colors.statusActiveBackground;
      case 1:
        return theme.colors.statusInactiveBackground;
      case 2:
        return theme.colors.textUncompletedStep;
      default:
        return theme.colors.textUncompletedStep;
    }
  }};
  color: ${({ currentActive, status, theme }) => {
    switch (status) {
      case 0:
        if (currentActive) {
          return theme.colors.statusTextOrange;
        }
        return theme.colors.statusActiveText;
      case 1:
        return theme.colors.statusInactiveText;
      case 2:
        return theme.colors.text3;
      default:
        return theme.colors.text3;
    }
  }};
`;

export const AppointmentStatusDot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${({ currentActive, status, theme }) => {
    switch (status) {
      case 0:
        if (currentActive) {
          return theme.colors.statusTextOrange;
        }
        return theme.colors.statusActiveText;
      case 1:
        return theme.colors.statusInactiveText;
      case 2:
        return theme.colors.text3;
      default:
        return theme.colors.text3;
    }
  }};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;
