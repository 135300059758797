import React from "react";
import PropTypes from "prop-types";
import { ActionButtonContainer } from "./ActionButton.styled";
import { VARIANT } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

export const ActionButton = (props) => {
  const { t } = useTranslation();
  return (
    <ActionButtonContainer
      disabled={props?.disabled}
      variant={VARIANT.CONTAINED}
      onClick={props.onClick}
      value={t(props.value)}
      startIcon={props.startIcon}
      isCancel={props?.isCancel}
    />
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  startIcon: PropTypes.node,
  isCancel: PropTypes.bool,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {};

export default ActionButton;
