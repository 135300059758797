import { TEXTVARIANT } from "constants/textConstants";
import {
  ArrowStyled,
  CardFirstStyled,
  CardFirstText,
  CardFirstTitle,
  CardImageContainer,
  CardImageContainerSecond,
  CardImageContainerSecondNotLogged,
  CardSecondStyled,
  CardSecondText,
  CardSecondTitle,
  CardSectionContainer,
  TextContainer,
} from "./FirstSection.styled";
import React from "react";
import { PAGES } from "constants/pages";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/useAuth";
import PropTypes from "prop-types";

const FirstSection = (props) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  return (
    <CardSectionContainer>
      <CardFirstStyled>
        <CardImageContainer />
        <TextContainer>
          <CardFirstTitle variant={TEXTVARIANT.H3}>
            {t("home.firstSection.firstCardTitle")}
          </CardFirstTitle>
          <CardFirstText>{t("home.firstSection.firstCardText")}</CardFirstText>
        </TextContainer>
      </CardFirstStyled>
      <CardSecondStyled
        isLoggedIn={isLoggedIn}
        to={isLoggedIn ? PAGES.APPOINTMENTS.route : PAGES.ABOUT.route}
      >
        {isLoggedIn ? (
          <CardImageContainerSecond />
        ) : (
          <CardImageContainerSecondNotLogged />
        )}
        <TextContainer>
          <CardSecondTitle>
            {isLoggedIn
              ? t("home.firstSection.secondCardLoggedInTitle")
              : t("home.firstSection.secondCardNotLoggedInTitle")}
          </CardSecondTitle>
          {isLoggedIn ? (
            props?.paginatedAppointments &&
            props?.paginatedAppointments.length > 0 ? (
              props?.paginatedAppointments.map((appointment) => {
                const localDate = new Date(appointment.fromTimeUtc);
                const time = convertUTCToLocalTime(appointment.fromTimeUtc);

                let formattedDate = localDate.toLocaleDateString(
                  authScopeStringGetHelper(LANGUAGE),
                  {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                );
                formattedDate = formattedDate
                  .split(" ")
                  .map((word, index) =>
                    index === 0 || index === 2
                      ? word.charAt(0).toUpperCase() + word.slice(1)
                      : word
                  )
                  .join(" ");
                return (
                  <CardSecondText key={appointment.id}>
                    {t("home.firstSection.appointmentText", {
                      date: formattedDate,
                      time: time,
                      appointmentService: appointment.clientService.name,
                    })}
                  </CardSecondText>
                );
              })
            ) : (
              <CardSecondText>
                {t("home.firstSection.secondCardLoggedInText")}
              </CardSecondText>
            )
          ) : (
            <CardSecondText>
              {t("home.firstSection.secondCardNotLoggedInText")}
            </CardSecondText>
          )}
          <ArrowStyled />
        </TextContainer>
      </CardSecondStyled>
    </CardSectionContainer>
  );
};

FirstSection.propTypes = {
  paginatedAppointments: PropTypes.array,
};

export default FirstSection;
