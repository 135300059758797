import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 593px;
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 285px;
  width: 233px;
  border-radius: 16px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;
