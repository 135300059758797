import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  ActionContainer,
  InfoContainer,
  MainContainer,
  NameContainer,
  StatusContainer,
  StatusButtonContainer,
  StatusText,
  ActiveCircle,
  LongDash,
  ImageAndNameContainer,
  DataContainer,
  StatusAndImageAndNameContainer,
  FieldsContainer,
  AvatarStyled,
  RightContainer,
  LeftContainer,
  DeleteAccountButton,
  DeleteAndStatusButtonContainer,
  SecondStatusContainer,
} from "./UserPageContent.styled";
import { useTranslation } from "react-i18next";
import BackButton from "components/RightAuthContent/Buttons/BackButton/BackButton";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";
import EmailField from "components/InputFields/EmailField";
import FirstNameField from "components/RegistrationContent/InputFields/FirstNameField";
import LastNameField from "components/RegistrationContent/InputFields/LastNameField";
import CountryField from "components/RegistrationContent/InputFields/CountryField";
import CityField from "components/RegistrationContent/InputFields/CityField";
import AddressField from "components/RegistrationContent/InputFields/AddressField";
import PhoneField from "components/RegistrationContent/InputFields/PhoneField/PhoneField";
import { useFormik } from "formik";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import {
  useChangeUserStatusMutation,
  useUpdateUserMutation,
} from "features/user/usersApiSlice";
import { UserValidationSchema } from "validations/userInfoValidation";
import { userParams } from "initialValues/entityFormikParams/userFormikParams";
import { SaveButtonWrapper } from "components/ClientEditTab/ClienEditTab.styled";
import { useDispatch, useSelector } from "react-redux";
import { isDataSaved, resetFlag, toggleFlag } from "features/user/userSlice";

export const UserPageContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const [changeUserStatus] = useChangeUserStatusMutation();
  const flag = useSelector(isDataSaved);

  const isActive = useMemo(() => {
    return props?.user?.isActive;
  }, [props?.user]);

  const onClickStatusButton = async () => {
    const response = await changeUserStatus(props?.user?.id);
    response.data.isSuccess
      ? makeToastMessage(
          t("superAdmin.table.users.successfullyChangedStatus"),
          "success"
        )
      : makeToastMessage(
          t("superAdmin.table.users.unsuccessfullyChangedStatus"),
          "error"
        );
  };

  const onClickDeleteAccount = () => {};

  const initials =
    props?.user?.firstName && props?.user?.lastName
      ? `${props?.user.firstName[0]}${props?.user.lastName[0]}`.toUpperCase()
      : null;

  const handleSubmit = async () => {
    const data = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      country: formik.values.country.name,
      city: formik.values.city.name,
      address: formik.values.address,
      phoneNumber:
        formik.values.countryPhoneCode.name + formik.values.phoneNumber,
    };

    let response = null;
    response = await updateUser({
      userId: props?.user?.id,
      userData: data,
    });

    if (response?.error) {
      makeToastMessage(
        t("superAdmin.table.clients.unsuccessfullyUpadtedClient"),
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("superAdmin.table.clients.successfullyUpdatedClient"),
        toastNotificationType.SUCCESS
      );
      dispatch(resetFlag());
    }
  };

  const formik = useFormik({
    initialValues: userParams(props?.user),
    validationSchema: UserValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      dispatch(resetFlag());
    } else if (formik.dirty) {
      dispatch(toggleFlag());
    }
  }, [formik.errors, formik.dirty]);

  return (
    <MainContainer>
      <LeftContainer>
        <InfoContainer>
          <StatusAndImageAndNameContainer>
            {props?.isSuperAdmin && (
              <StatusContainer>
                <StatusText active={isActive}>
                  {isActive
                    ? t("superAdmin.table.users.labelStatusActive")
                    : t("superAdmin.table.users.labelStatusInactive")}
                </StatusText>
                <StatusButtonContainer
                  active={isActive}
                  onClick={onClickStatusButton}
                >
                  <ActiveCircle>
                    {isActive ? <CheckImg /> : <XImg />}
                  </ActiveCircle>
                </StatusButtonContainer>
              </StatusContainer>
            )}
            <ImageAndNameContainer>
              <AvatarStyled>{initials}</AvatarStyled>
              <NameContainer>
                {props?.user?.firstName + " " + props?.user?.lastName}
              </NameContainer>
            </ImageAndNameContainer>
          </StatusAndImageAndNameContainer>
          <DataContainer>
            <LongDash />
            <FieldsContainer>
              <FirstNameField formik={formik} disabled={props?.isSuperAdmin} />
              <LastNameField formik={formik} disabled={props?.isSuperAdmin} />
              <EmailField formik={formik} disabled />
              <PhoneField formik={formik} disabled={props?.isSuperAdmin} />
              <CountryField formik={formik} disabled={props?.isSuperAdmin} />
              <CityField formik={formik} disabled={props?.isSuperAdmin} />
              <AddressField formik={formik} disabled={props?.isSuperAdmin} />
            </FieldsContainer>
          </DataContainer>
        </InfoContainer>
        {props?.isSuperAdmin && (
          <ActionContainer>
            <BackButton onClick={props.onClickBackBtn} />
          </ActionContainer>
        )}
      </LeftContainer>
      <RightContainer>
        <DeleteAndStatusButtonContainer>
          <DeleteAccountButton
            value={t("user.deleteAccount")}
            onClick={onClickDeleteAccount}
          />
          {!props?.isSuperAdmin && (
            <SecondStatusContainer>
              <StatusText active={isActive}>
                {isActive
                  ? t("superAdmin.table.users.labelStatusActive")
                  : t("superAdmin.table.users.labelStatusInactive")}
              </StatusText>
              <StatusButtonContainer
                active={isActive}
                onClick={onClickStatusButton}
              >
                <ActiveCircle>
                  {isActive ? <CheckImg /> : <XImg />}
                </ActiveCircle>
              </StatusButtonContainer>
            </SecondStatusContainer>
          )}
        </DeleteAndStatusButtonContainer>
        <SaveButtonWrapper
          value={t("common.save")}
          onClick={formik?.handleSubmit}
          disabled={!flag}
        />
      </RightContainer>
    </MainContainer>
  );
};

UserPageContent.propTypes = {
  user: PropTypes.object,
  onClickBackBtn: PropTypes.func,
  myAccountFlag: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
};

export default UserPageContent;
