import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { useGetClientByIdQuery } from "features/clients/clientsApiSlice";
import ClientPanelForUsers from "components/ClientPanelForUsers/ClientPanelForUsers";
import { useParams } from "react-router-dom";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { useDispatch } from "react-redux";
import { setClient } from "features/clients/clientSlice";
import { useIsLoading } from "hooks/useIsLoading";
import { setServiceCategory } from "features/serviceCategory/serviceCategorySlice";

const ClientPanelForUsersPage = () => {
  useIsLoading();
  const dispatch = useDispatch();
  const params = useParams();

  const { data, isLoading } = useGetClientByIdQuery(params.clientId);
  const client = isLoading ? null : data?.data?.client;

  useEffect(() => {
    dispatch(setServiceCategory(null));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      dispatch(setClient({ ...client }));
    }
  }, [client]);
  return (
    <MainLayout>
      <PageHeader leftIcon={<ControlTableIconSelected />} />
      <ClientPanelForUsers client={client} />
    </MainLayout>
  );
};

ClientPanelForUsersPage.propTypes = {
  theme: PropTypes.any,
};

export default ClientPanelForUsersPage;
