import AddressField from "components/RegistrationContent/InputFields/AddressField";
import CityField from "components/RegistrationContent/InputFields/CityField";
import CompanyNameField from "components/RegistrationContent/InputFields/CompanyNameField";
import CountryField from "components/RegistrationContent/InputFields/CountryField";
import PhoneField from "components/RegistrationContent/InputFields/PhoneField/PhoneField";
import PrimaryServiceField from "components/RegistrationContent/InputFields/PrimaryServiceField";
import UsernameField from "components/RegistrationContent/InputFields/UsernameField";
import {
  InputFieldContainer,
  InputFieldsContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import React, { useState } from "react";
import PropTypes from "prop-types";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { ClientStepOneValidation } from "validations/registerClientValidation";
import { useLazyCheckClientRegistrationNameQuery } from "features/register/registerApiSlice";
import { useDispatch } from "react-redux";
import { setAttributesStepOne } from "features/register/registerClientSlice";
import { clientColors } from "themes/secondaryTheme/secondaryThemeColors";
import { FormikParams } from "constants/formikParams";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientAttributesStepOne } from "features/register/registerClientSlice";

const ClientStepOne = (props) => {
  const [regNameNotUniqueError, setRegNameNotUniqueError] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clientDataStepOne = useSelector(selectClientAttributesStepOne);
  const [triggerCheckClientRegistrationName] =
    useLazyCheckClientRegistrationNameQuery();

  const reverseMappedData = {
    username: clientDataStepOne?.registrationName,
    companyName: clientDataStepOne?.fullName,
    address: clientDataStepOne?.address,
    phoneNumberCode: clientDataStepOne?.phoneNumberCode,
    phoneNumber: clientDataStepOne?.phoneNumber,
    country: clientDataStepOne?.country,
    city: clientDataStepOne?.city,
    primaryService: clientDataStepOne?.serviceCategoryId,
  };
  const handleSubmit = async () => {
    const data = {
      registrationName: formik?.values?.username,
      fullName: formik?.values?.companyName,
      country: formik?.values?.country,
      city: formik?.values?.city,
      address: formik?.values?.address,
      serviceCategoryId: formik?.values?.primaryService,
    };

    dispatch(
      setAttributesStepOne({
        ...data,
        countryPhoneCode: formik.values.countryPhoneCode.name,
        phoneNumber: formik.values.phoneNumber,
      })
    );

    props.onClickNextPageBtn();
  };

  const handleBlurUsername = async (e) => {
    formik.handleBlur(e);
    const result = await triggerCheckClientRegistrationName({
      name: formik.values.username,
    });

    if (result.data.data.isNameUnique === false) {
      setRegNameNotUniqueError(t("register.usernameAlreadyTaken"));
      return;
    } else {
      setRegNameNotUniqueError(null);
    }
  };
  const formik = useFormik({
    initialValues: {
      ...(reverseMappedData ?? null),
    },
    validationSchema: ClientStepOneValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const [selectedCountry, setSelectedCountry] = useState(FormikParams.country);
  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <InputFieldContainer twoInputs>
            <UsernameField
              formik={formik}
              required
              helperText={regNameNotUniqueError}
              clearErrorMessage={() => setRegNameNotUniqueError(null)}
              onBlur={handleBlurUsername}
            />
            <CompanyNameField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer>
            <PrimaryServiceField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer twoInputs>
            <CountryField
              value={formik?.values?.country}
              formik={formik}
              required
              setSelectedCountry={setSelectedCountry}
            />
            <CityField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer twoInputs>
            <AddressField formik={formik} required />
            <PhoneField
              formik={formik}
              required
              disabledCode
              selectedCountry={selectedCountry}
              selectFirst
              placeholder={"23 45 678"}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerRegContent
        disabled={regNameNotUniqueError ? true : false}
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={props.onClickBackBtn}
        currentStep={props.currentStep}
        colorPalet={clientColors}
      />
    </>
  );
};

ClientStepOne.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
};

export default ClientStepOne;
