import React from "react";
import PropTypes from "prop-types";
import { StatusWrapper, StatusDot } from "./Status.styled";
import { useTranslation } from "react-i18next";

const Status = ({
  isActive,
  activeTextKey,
  inactiveTextKey,
  showDot = true,
}) => {
  const { t } = useTranslation();
  return (
    <StatusWrapper isActive={isActive}>
      {showDot && <StatusDot isActive={isActive} />}
      {isActive ? t(activeTextKey) : t(inactiveTextKey)}
    </StatusWrapper>
  );
};

Status.propTypes = {
  isActive: PropTypes.bool.isRequired,
  activeTextKey: PropTypes.string.isRequired,
  inactiveTextKey: PropTypes.string.isRequired,
  showDot: PropTypes.bool,
};

export default Status;
