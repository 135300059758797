import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSelectFieldStyled from "../../InputFields/CustomFields/CustomSelectField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";
import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";

const CityField = (props) => {
  const { t } = useTranslation();
  const handleChange = (changedValue) => {
    props.formik.setFieldValue("city", changedValue);
  };
  const { data, isLoading } = useGetAllCitiesQuery();
  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomSelectFieldStyled
        name={props.name}
        formik={props.formik}
        value={props?.formik?.values?.city}
        placeholder={t(props.placeholder)}
        items={
          isLoading
            ? []
            : data?.data?.cityNames?.map((name) => {
                return {
                  id: name,
                  name,
                };
              })
        }
        width={props.width}
        passValue={handleChange}
        disabled={props?.disabled}
      />
    </Box>
  );
};

CityField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  disabled: PropTypes.bool,
};

CityField.defaultProps = {
  label: "common.labelCity",
  name: FormikParams.city,
  placeholder: "common.citySelect",
};

export default CityField;
