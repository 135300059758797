import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { HorizontalSlideContainer } from "./HorizontalSlide.styled";

export const HorizontalSlide = (props) => {
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      addMouseDragging(listRef);
    }
  }, []);

  const addMouseDragging = (ref) => {
    let isDown = false;
    let startX;
    let scrollLeft;
    let isMoved = false;

    ref.current.addEventListener("mousedown", (event) => {
      isDown = true;
      isMoved = false;
      props?.setIsDragging(false);
      ref.current.classList.add("active");
      startX = event.pageX - ref.current.offsetLeft;
      scrollLeft = ref.current.scrollLeft;
    });

    ref.current.addEventListener("mouseleave", () => {
      isDown = false;
      ref.current.classList.remove("active");
    });

    ref.current.addEventListener("mouseup", () => {
      isDown = false;
      ref.current.classList.remove("active");
      if (isMoved) {
        props?.setIsDragging(true);
      }
    });

    ref.current.addEventListener("mousemove", (event) => {
      if (!isDown) return;
      event.preventDefault();
      const x = event.pageX - ref.current.offsetLeft;
      const walk = (x - startX) * 2;
      ref.current.scrollLeft = scrollLeft - walk;

      if (Math.abs(x - startX) > 10) {
        isMoved = true;
      }
    });
  };

  return (
    <HorizontalSlideContainer ref={listRef}>
      {props.children}
    </HorizontalSlideContainer>
  );
};

HorizontalSlide.propTypes = {
  children: PropTypes.node,
  setIsDragging: PropTypes.func,
};

HorizontalSlide.defaultProps = {};

export default HorizontalSlide;
