import { Box, Switch } from "@mui/material";
import styled from "styled-components";

import { ReactComponent as ThumbIconFalse } from "assets/images/svg/switch-false-icon.svg";

import { ReactComponent as ThumbIconTrue } from "assets/images/svg/switch-check.svg";

export const SingleFormStyled = styled(Box)``;

export const ThumbTrue = styled(ThumbIconTrue)`
  box-shadow: none;
  width: 20px;
  height: 20px;

  color: ${({ theme }) => theme.colors.white};
  background-repeat: no-repeat;
  background-position: center;
`;
export const ThumbFalse = styled(ThumbIconFalse)`
  box-shadow: none;
  width: 20px;
  height: 20px;

  color: ${({ theme }) => theme.colors.white};

  background-repeat: no-repeat;
  background-position: center;
`;

export const ClientSwitchStyled = styled(Switch)`
  margin: 10px;
  padding: 8px;

  & .MuiSwitch-track {
    border-radius: 11px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(50%);
      width: 16px;
      height: 16px;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props?.theme?.colors?.greenCheck};
  }
`;
