import styled from "styled-components";
import { ReactComponent as TrashIcon } from "assets/images/svg/table-row-delete-icon.svg";
import {
  ActionButton,
  CancelActionButton,
  LowerContainer,
  UpperContainer,
} from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal.styled";

export const UpperContainerWrapper = styled(UpperContainer)`
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const TrashIconStyled = styled(TrashIcon)`
  height: 56px;
  width: 56px;
  align-self: center;
  margin-top: 15px;
`;

export const CancelButton = styled(CancelActionButton)`
  border-color: ${(props) => props?.theme?.colors?.text3};
  font-size: 14px;
`;

export const DeleteButton = styled(ActionButton)`
  font-size: 14px;
  background-color: ${(props) => props?.theme?.colors?.errorColor};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.errorColorHover};
  }
`;

export const LowerContainerWrapper = styled(LowerContainer)`
  flex-direction: row;
  gap: 10px;
  height: 70px;
  padding: 10px;
`;
