import styled from "styled-components";

export const NotificationBadgeStyled = styled.div`
  border-radius: 180px;
  background-color: ${(props) => props?.theme?.colors?.errorColor};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -5px;
`;

export const MessageWrapper = styled.div`
  position: relative;
`;
