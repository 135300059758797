import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../InputFields/CustomFields/CustomTextField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";

const AddressField = (props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomTextField
        type={props.type}
        name={props.name}
        placeholder={t(props.placeholder)}
        formik={props.formik}
        width={props.width}
        disabled={props?.disabled}
      />
    </Box>
  );
};

AddressField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  disabled: PropTypes.bool,
};

AddressField.defaultProps = {
  label: "common.labelAddress",
  name: FormikParams.address,
  placeholder: "common.addressInput",
};

export default AddressField;
