import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: ${(props) => {
    return props?.isAuthLayout ? props.theme.colors.backgroundColor : "white";
  }};
`;

export const ContentWrapper = styled.div`
  margin-left: ${({ collapsed }) => (collapsed ? "95px" : "300px")};
  transition: margin-left 0.3s ease;
  padding: 0px 44px 0px 64px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
  width: 100%;
  overflow-y: auto;
  justify-content: space-between;
`;

export const Content = styled.div``;

export const ContentWrapperCentered = styled.div`
  padding: 0px 14px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
  width: 100%;
  overflow-y: auto;
  justify-content: space-between;
`;

export const ContentCentered = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background-color: ${(props) => props?.theme?.colors?.backgroundColor};
  padding: 24px;
  margin-top: 30px;
  height: 50%;
`;
