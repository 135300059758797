export const userFormikParams = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  city: "city",
  country: "country",
  address: "address",
  countryPhoneCode: "countryPhoneCode",
  phoneNumber: "phoneNumber",
};
