import React from "react";
import {
  ClientServiceTableHeaderSyled,
  ClientServiceTableHeaderTypography,
} from "./ClientServiceTableHeader.styled";
import PropTypes from "prop-types";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";

const ClientServiceTableHeader = (props) => {
  return (
    <ClientServiceTableHeaderSyled>
      <ClientServiceTableHeaderTypography>
        {props.headerTitle}
      </ClientServiceTableHeaderTypography>
      <AddNewItemButton
        value={props?.addButtonValue}
        onClick={props.handleOpenModalAdd}
      />
    </ClientServiceTableHeaderSyled>
  );
};
ClientServiceTableHeader.propTypes = {
  headerTitle: PropTypes.string,
  handleOpenModalAdd: PropTypes.func,
  addButtonValue: PropTypes.string,
};

export default ClientServiceTableHeader;
