import styled from "styled-components";
import { Box } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const LeftContentWrapper = styled(Box)`
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  border-radius: 24px;
  width: 410px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 16px;
  background-color: ${(props) => props?.theme?.colors?.white};
`;

export const ClientFormWrapper = styled(Box)`
  padding: 0px 10px 10px 10px;
`;

export const SegmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${(props) => props?.theme?.colors?.clientPageFormBorder};
  padding-top: 20px;
`;

export const SegmentContainer = styled.div`
  border-bottom: ${({ lastInput, theme }) =>
    lastInput ? "none" : `1px solid ${theme.colors.clientPageFormBorder}`};

  padding: 10px 0px 10px 0px;
`;
