import React from "react";
import PropTypes from "prop-types";
import { GridContainer, RowContainer } from "./ListingClientsComponent.styled";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import { useNavigate } from "react-router-dom";
import ClientCard from "components/LandingPageContent/ClientCard";

const ListingClientsComponent = (props) => {
  const navigate = useNavigate();
  const rows = [];
  for (let i = 0; i < props?.items?.length; i += props?.itemsPerRow) {
    rows.push(props?.items?.slice?.(i, i + props?.itemsPerRow));
  }

  const onClientClick = (client) => {
    const pathParams = {
      clientId: client?.id,
    };
    const resolvedRoute = replaceInRoute(PAGES.CLIENT.route, pathParams);
    navigate(resolvedRoute);
  };

  return (
    <GridContainer>
      {rows.map((row, rowIndex) => (
        <RowContainer key={rowIndex}>
          {row.map((client, itemIndex) => (
            <ClientCard
              key={itemIndex}
              onClick={() => onClientClick(client)}
              client={client}
            />
          ))}
        </RowContainer>
      ))}
    </GridContainer>
  );
};

ListingClientsComponent.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
  clientId: PropTypes.string,
};

export default ListingClientsComponent;
