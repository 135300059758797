import styled from "styled-components";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";
import { ImageContainer } from "components/ImageGallery/ImageGallery.styled";

export const GalleryContentContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
`;

export const LeftArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ImagesContainerWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ImageContainerStyled = styled(ImageContainer)`
  &:hover img {
    filter: brightness(100%);
  }
`;

export const RightArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LeftArrowStyled = styled(LeftArrow)``;

export const RightArrowStyled = styled(RightArrow)``;

export const ArrowButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* :hover {
    background-color: ${(props) => props?.theme?.colors?.boxShadow};
  } */
`;
