import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAllAsRead,
  selectNotifications,
  selectUnreadNotificationsCount,
} from "features/notificationsSlice/notificationsSlice";
import { useTranslation } from "react-i18next";
import { ContentContainer, StyledText } from "./NotificationTable.styled";
import Table from "components/Table/Table";
import { unstable_useBlocker } from "react-router-dom";

const NotificationTable = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const numberOfMessages = useSelector(selectUnreadNotificationsCount);
  const { t } = useTranslation();

  unstable_useBlocker(() => {
    if (notifications.some((notification) => !notification.read)) {
      dispatch(markAllAsRead());
    }
  });

  const tableHeaders = [
    { label: t("notification.message"), field: "message" },
  ];

  const formattedNotifications = useMemo(() => {
    const reversedNotifications = [...notifications].reverse();
    return reversedNotifications.map((notification) => {
      return {
        message: notification.message,
      };
    });
  }, [notifications]);

  return (
    <ContentContainer>
      {notifications.length > 0 ? (
        <Table
          tableHeaders={tableHeaders}
          items={formattedNotifications}
          startingNumberOfItemsPerPage={10}
          numberOfCharactersPerCell={200}
          newNotificationsCount={numberOfMessages}
          t={t}
        />
      ) : (
        <StyledText>{t("notification.noNotifications")}</StyledText>
      )}
    </ContentContainer>
  );
};

export default NotificationTable;
