export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo: {
    text: "Service Appointemnts",
  },
  common: {
    serviceCategory: "Osnovna delatnost",
    positiveOrNegativeNumber: "Mora biti pozitivan ili negativan broj.",
    positiveNumber: "Mora biti pozitivan broj.",
    finish: "Završi",
    serviceCategories: "serviceCategories",
    description: "Osnovne informacije",
    from: "Od",
    to: "Do",
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Zatvori",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelLocation: "Lokacija",
    labelUsername: "Korisničko ime",
    labelEmail: "Email",
    labelEmailAddress: "Email adresa",
    labelPassword: "Lozinka",
    labelCompanyName: "Naziv firme",
    labelPrimaryService: "Primarna delatnost",
    labelCountry: "Država",
    labelCity: "Grad",
    labelAddress: "Adresa",
    labelPhone: "Telefon",
    labelPhoneNumber: "Broj telefona",
    labelFirstName: "Ime",
    labelLastName: "Prezime",
    labelPasswordConfirmation: "Potvrda šifre",
    labelClientName: "Ime klijenta",
    clients: "Klijenti",
    status: "Status",
    usernameInput: "Unesite korisničko ime",
    passwordInput: "Unesite lozinku",
    email: "Unesite email",
    selectBtn: "Izaberi",
    companyNameInput: "Unesite naziv firme",
    primaryServiceSelect: "Izaberite primarnu delatnost",
    countrySelect: "Izaberite državu",
    citySelect: "Izaberite grad",
    addressInput: "Unesite adresu",
    phoneInput: "Unesite telefon",
    firstNameInput: "Unesite ime",
    lastNameInput: "Unesite prezime",
    passwordConfirmationInput: "Potvrdite šifru",
    codeInput: "Unesite kod",
    next: "Dalje",
    nextTablePage: "Sledeća >",
    nextPage: "Sledeća stranica",
    previousTablePage: "< Prethodna",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    giveUp: "Odustani",
    leave: "Napusti",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdi",
    printDownload: "Print/Download",
    cancel: "Otkaži",
    remove: "Ukloni",
    quit: "Odustani",
    deactivate: "Deaktiviraj",
    invite: "Invite",
    add: "Dodaj",
    save: "Sačuvaj",
    edit: "Izmeni",
    details: "Detalji",
    delete: "Obriši",
    complete: "Complete",
    download: "Download",
    yes: "Da",
    no: "Ne",
    or: "ili",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Izloguj se",
    seeMore: "Vidi još",
    users: "Korisnici",
    filter: "Filter",
    schedule: "Zakaži",
    admin: "Admin",
    scheduleAppointment: "Zakaži termin",
  },
  imageGallery: {
    images: "Fotografije",
    addImage: "Privuci ili dodaj fotografiju",
  },
  code: {
    sentEmailMessage:
      "Poslali smo Vam kod za potvrdu na email adresu koju ste naveli. Molimo Vas da proverite svoju email adresu i unesete kod kako biste završili proces registracije.",
    labelSentEmailCodeInput: "Unesite kod koji Vam je stigao na mail",
    sentPhoneMessage:
      "Poslali smo Vam kod za potvrdu na broj telefona kojiste naveli. Molimo vas da proverite telefon i unesete kod kako biste završili proces registracije.",
    labelSentPhoneCodeInput: "Unesite kod koji Vam je stigao u SMS poruci",
    sendNewCode: "Zatražite novi kod",
  },
  notifications: {
    title: "Obaveštenja",
  },
  landingPage: {
    popularClients: "Popularni klijenti",
    favouriteClients: "Omiljeni klijenti",
  },
  pages: {
    landing: "Početna",
    clientService: "Usluga",
    home: "Dobrodošli na Termium",
    login: "Login",
    users: "Korisnici",
    clients: "Klijenti",
    client: "Klijent",
    register: "Register",
    resources: "Resursi",
    info: "Informacije",
    calendar: "Kalendar",
    licenses: "Licence",
    forgotPassword: "Zaboravljena lozinka",
    resetPassword: "Reset passworda",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    dashboard: "Komandna tabla",
    services: "Usluge",
    controlTable: "Kontrolna tabla",
    serviceCategories: "Delatnosti",
    about: "O platformi",
    help: "Pomoć",
    contact: "Kontakt",
    messages: "Poruke",
    myAppointments: "Moji termini",
    scheduling_appointment: "Zakazivanje termina",
    myAccount: "Moj nalog",
  },
  register: {
    registerTitle: "Registrujte se",
    alreadyHaveAccount: "Već imate nalog?",
    usernameRequired: "Korisničko ime je obavezno.",
    emailFormat: "Format email-a nije validan.",
    passwordLength: "Šifra mora da sadrži između 8 i 50 karaktera.",
    emailRequired: "An email or username is required.",
    passwordsDoNotMatch: "Šifre se ne poklapaju.",
    passwordUppercase: "Šifra mora da sadrži veliko slovo.",
    passwordLowercase: "Šifra mora da sadrži malo slovo.",
    passwordNumber: "Šifra mora da sadrži broj.",
    passwordSpecial: "Šifra mora da sadrži specijalni karakter.",
    passwordRequired: "A Password is required.",
    passwordConfirm: "Potvrdite šifru",
    inputRequired: "Polje je obavezno.",
    usernameAlreadyTaken: "Koriscničko ime se već koristi.",
    emailAlreadyTaken: "Email se već koristi.",
    codeLength: "Pogrešna duzina koda",
    inccorrectCode: "Pogrešan validacioni kod",
    progress: {
      title: {
        client: "Registrujte se kao pružalac usluga u par koraka",
        user: "Registrujte se kao korisnik usluga u par koraka",
      },
      client: {
        steps: [
          "Informacije o pružaocu usluga",
          "Lični podaci:",
          "Potvrdite Vaš e-mail",
        ],
      },
      user: {
        steps: ["Lični podaci:", "Dodatne informacije", "Potvrdite Vaš e-mail"],
      },
    },
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Loš format email-a",
    emailRequired: "Email/korisničko ime je obavezno",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Vaša lozinka mora sadržati između 8 i 16 znakova.",
    signUpRecommendation: "Registrujte se",
    email: "Unesite svoj email",
    password: "Unesite šifru",
    logInTitle: "Ulogujte se",
    logIn: "Ulogujte se",
    continueWithGoogle: "Nastavite preko google-a",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmailSent: "Ako imate nalog uskoro će vam stići email.",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  scheduleAppointment: {
    chooseAttribute: "Odaberi atribute:",
    firstAvailable: "Prvi slobodan",
    searchForAppointments: "Pretraži termine",
    chooseTime: "Odaberi vreme:",
    calendar: "Kalendar",
    periodLabel: "Period:",
    dateLabel: "Datum",
    noteLabel: "Napomena",
    notePlaceholder: "Unesite napomenu",
    successfullyScheduledAppointment: "Uspešno zakazan termin",
    addClientToFav:
      "Da li želite da dodate ovog klijenta u vašu listu omiljenih?",
    clientAddedToFavourites: "Klijent je dodat u listu omiljenih.",
  },
  appointments: {
    addAppointment: "Dodaj termin",
    resourcesValidation: "Mora biti označen bar 1 resurs",
    clientServiceIdValidation: "Usluga mora biti izabrana",
    fromDate: "Poćetak termina",
    toDate: "Kraj termina",
    clientService: "Usluga",
    fromDatePlaceholder: "Izaberite početni datum i vreme",
    toDatePlaceholder: "Izaberite završni datum i vreme",
    clientServicePlaceholder: "Izaberite uslugu",
    cancelAppointmentQuestion:
      "Da li ste sigurni da želite da otkažete termin?",
    cancelAppointmentResponse: "Uspešno otkazan termin.",
    tooltips: {
      inProgress: "Termin je u toku",
      cancelled: "Termin je otkazan",
      done: "Termin je završen",
      allowedTimeExceeded:
        "Termin se otkazuje do {{allowedTime}} h pre realizacije",
    },
    filters: {
      fromDate: "Od datuma",
      fromDatePlaceholder: "Izaberite početni datum",
      toDate: "Do datuma",
      toDatePlaceholder: "Izaberite krajnji datum",
      status: "Status",
      statusPlaceholder: "Izaberite status",
      client: "Klijent",
      inputClientName: "Unesite ime klijenta",
      priceFrom: "Cena od",
      priceTo: "Cena do",
      UserFirstName: "Ime korisnika",
      UserLastName: "Prezime korisnika",
      inputUserFirstName: "Unesite ime korisnika",
      inputUserLastName: "Unesite prezime korisnika",
    },
    appointmentStatus: {
      reserved: "Aktivan",
      cancelled: "Otkazan",
      done: "Završen",
      inProgress: "U toku",
    },
    table: {
      date: "Datum",
      time: "Vreme",
      serviceName: "Usluga",
      clientName: "Klijent",
      user: "Korisnik",
      price: "Cena",
    },
    noAppointmentsMessage:
      "Trenutno nemate zakazan ni jedan termin, kada budete zakazali, ovde će vam se prikazati predstojeći termini.",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Zaboravili ste šifru",
    label: "Pošaljite email",
    emailRequired: "Email je obavezan.",
    emailFormat: "Nevalidan format za email.",
    resetPassword: "Resetujte lozinku",
    password: "Lozinka",
    repeatPassword: "Ponovite lozinku",
    resetPasswordEmailText: "Unesite email za resetovanje lozinke",
    savePassword: "Sačuvajte lozinku",
    passwordChanged: "Lozinka je uspešno promenjena",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Resetujte lozinku",
    },
    sentEmailMessage:
      "Molimo vas da proverite svoj email i sledite uputstva iz poruke kako biste kreirali novu lozinku.",
  },
  resetPassword: {
    label: "Resetuj šifru",
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },

  home: {
    firstSection: {
      firstCardTitle:
        "Pronađite usluge u blizini i rezervišite termine brzo i lako!",
      firstCardText:
        "Termium vam omogućava brz i jednostavan način da pronađete slobodan termin i rezervišete odmah!",
      secondCardNotLoggedInTitle:
        "Povežite se sa korisnicima koji traže vaše usluge!",
      secondCardNotLoggedInText:
        "Zahvaljujući našoj platformi, omogućite klijentima da vas pronađu i zakažu u nekoliko klikova. Brzo se prijavite i pratite kako raste broj vaših zakazanih termina!",
      secondCardLoggedInTitle: "Vaši naredni termini",
      secondCardLoggedInText:
        "Trenutno nemate zakazan ni jedan termin, kada zakažete prikazaće vam se naredni termini koji vas iščekuju.",
      appointmentText: "{{date}} u {{time}}h - {{appointmentService}}",
    },
  },

  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
  middlepage: {
    alreadyGotAccount: "Već imate nalog? ",
    user: {
      title: "Registruj se kao <0>korisnik</0> usluga",
      text: "Želiš da na brz i jednostavan način zakažeš termin za uslugu koja ti je potrebna",
      button: "Registruj se kao korisnik",
    },
    client: {
      title: "Registruj se kao <0>pružalac</0> usluga",
      text: "Baviš se pružanjem usluga i potreban ti je lak način da zakazuješ termine",
      button: "Registruj se kao klijent",
    },
  },
  clientService: {
    newClientService: "Nova usluga",
    successfulyDeleted: "Uspešno obrisano",
    services: "Usluge",
    active: "Aktivna",
    notActive: "Neaktivna",
    clientServiceSuccessfullyEdited: "Uspešno izmenjena usluga",
    clientServiceSuccessfullyAdded: "Uspešno dodata usluga",
    serviceDeletePrompt: "Da li ste sigurni da želite da izbrišete uslugu?",
    addButonForServices: "Dodaj novu uslugu",
    service: "Usluga: {{serviceName}}",
    serviceName: "Ime usluge",
    serviceStatus: "Status usluge",
    serviceDescription: "Opis usluge",
    serviceDuration: "Trajanje usluge",
    servicePrice: "Cena usluge",
    serviceActive: "Usluga aktivna",
    serviceResources: "Resursi",
    serviceAttributes: "Atributi",
    add: "Dodaj",
    placeholderName: "Unesite ime usluge",
    placeholderDescription: "Unesite opis usluge",
    placeholderDuration: "Unesite trajanje usluge",
    placeholderPrice: "Unesite cenu usluge",
    editResourceGroup: "Izmeni resurs grupu",
    addResourceGroup: "Dodaj resurs grupu",
    resourceTypeName: "Naziv grupe",
    resourceList: "Lista resursa",
    attributeList: "Lista atributa",
    placeholderResourceGroupName: "Unesite naziv grupe",
    placeholderResource: "Unesite resurse",
    resourceAutoAssign: "Automatska dodela resursa",
    editAttribute: "Izmeni atribut",
    addAttribute: "Dodaj atribut",
    attributeName: "Naziv atributa",
    attributeDescription: "Opis atributa",
    attributeAdditionalTime: "Dodatno vreme atributa",
    attributeAdditionalPrice: "Dodatna cena atributa",
    placeholderAttributeName: "Unesite naziv atributa",
    placeholderAttributeDescription: "Unesite opis atributa",
    placeholderAttributeAdditionalTime: "Unesite vreme koje atribut dodaje",
    placeholderAttributeAdditionalPrice: "Unesite cenu koju atribut dodaje",
    table: {
      resources: {
        addResource: "Dodaj novi resurs",
        groupName: "Naziv grupe",
        autoAssign: "Automatska dodela resursa",
        resources: "Resursi",
      },
      attributes: {
        addAttribute: "Dodaj novi atribut",
        name: "Naziv",
        description: "Opis",
        additionalTime: "Dodatno vreme",
        additionalPrice: "Dodatna cena",
      },
    },
    filters: {
      minimalDurationInMinutesLabel: "Min dužina trajanja",
      maximalDurationInMinutesLabel: "Max dužina trajanja",
      minimalPriceLabel: "Cena od",
      maximalPriceLabel: "Cena do",
    },
    breadCrumbs: {
      placeholder: "Plejsholder",
    },
  },
  validationmessage: {
    sentCodeTitle: "Poslat Vam je novi kod!",
    client: {
      title: "Uspešno ste se registrovali!",
      text: "Spremni ste za prve klijente. Pratite zakazivanja i upravljajte terminima na jednostavan način.",
    },
    user: {
      title: "Vaša registracija je završena!",
      text: "Sada možete jednostavno zakazivati termine za željene usluge.",
    },
    button: "Zatvori",
  },
  clientPanel: {
    subscriptions: {
      addToFavourites: "Dodaj u omiljene",
      removeFromFavourites: "Ukloni iz omiljenih",
      addedToFavourites: "Dodat u omiljene",
      removedFromFavourites: "Uklonjen iz omiljenih",
    },
    infoSegment: {
      title: {
        workingHours: "Radno vreme",
        fullName: "Puno ime",
        primaryService: "Primarna delatnost",
        additionalServices: "Dodatne delatnosti",
        webAddress: "Web adresa",
      },
    },
    servicesSegment: {
      title: "Usluge",
    },
  },
  superAdmin: {
    pageTitle: "Super admin",
    dashboardTitle: "Kontrolna tabla",
    table: {
      numberOfShowingResultsBeforeSelect: "Prikaži",
      numberOfShowingResultsAfterSelect: "od {{total}} podataka",
      tableHeaderForClients: "Klijenti",
      tableHeaderForUsers: "Korisnici",
      tableHeaderForServiceCategories: "Delatnosti",
      tableHeaderForServiceCategoriesDescription: "Opis usluge",
      tableHeaderForLicenses: "Licence",
      tableHeaderForResources: "Resursi",
      editButton: "Izmeni",
      serviceCategories: {
        addButtonForServiceCategories: "Dodaj novu delatnost",
        editButtonForServiceCategories: "Izmeni delatnost",
        descriptionForServiceCategories: "Opis",
        successfullyAdded: "Uspešno dodata delatnost",
        successfullyUpdated: "Uspešno ažurirana delatnost",
      },
      clients: {
        addButtonForClients: "Dodaj novog klijenta",
        locationForClients: "Lokacija",
        statusForClients: "Status",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
        userPanel: "Korisnički panel",
        labelStatusActive: "Klijent aktivan:",
        labelStatusInactive: "Klijent neaktivan:",
        successfullyUpdatedClient: "Uspešno sačuvani podaci!",
        successfullyUpdatedClientLogo: "Uspešno sačuvan logo!",
        unsuccessfullyUpdatedClient: "Neuspešno sačuvani podaci!",
        confirmationStatusChangeMessage:
          "Da li ste sigurni da želite da {{action}} klijenta?",
        successfullyChangedStatus: "Uspešno promenjen status klijenta",
        unsuccessfullyChangedStatus: "Neuspešno promenjen status klijenta",
        unsavedData:
          "Vaše promene neće biti sačuvane, da li ste sigurni da zelite da napustite stranicu?",
        filters: {
          clientNameInput: "Unesi ime klijenta",
          citySelect: "Izaberi grad",
          allCities: "Svi gradovi",
          statusSelect: "Izaberi status",
          allStatuses: "Svi statusi",
          primaryServiceSelect: "Izaberi primarnu delatnost",
          allServices: "Sve delatnosti",
        },
        details: {
          fullName: "Naziv:",
          registrationName: "Pun naziv:",
          email: "Email Adresa:",
          phoneNumber: "Broj telefona:",
          country: "Država:",
          city: "Grad:",
          workDays: "Radni dani:",
          userBookingSetting: "Zakazivanje za korisnike:",
          primaryServiceCategory: "Osnovna delatnost:",
          webAddress: "Web stranica:",
          address: "Adresa:",
          workHours: "Radno vreme:",
          clientStatus: "Status klijenta:",
          appointmentStartTimeSetting: "Početak termina na:",
          requiredDescription: "Dodaj opis",
        },
        tabs: {
          informations: "Informacije",
          resources: "Resursi",
          services: "Usluge",
          calendar: "Kalendar",
          license: "Licenca",
        },
      },
      clientResources: {
        addResource: "Dodaj novi resurs",
        editResource: "Izmeni resurs",
        resource: "Tip resursa",
        chooseResource: "Izaberi tip resursa",
        resourceType: {
          employee: "Zaposleni",
          equipment: "Oprema",
          premise: "Prostorije",
          other: "Ostalo",
        },
        status: "Status resursa",
      },
      users: {
        addButtonForUsers: "Dodaj novog korisnika",
        filters: {
          userNameInput: "Unesi ime korisnika",
          emailInput: "Unesi email",
          citySelect: "Izaberi grad",
          allCities: "Svi gradovi",
          statusSelect: "Izaberi status",
          allStatuses: "Svi statusi",
        },
        labelStatusActive: "Aktivan:",
        labelStatusInactive: "Neaktivan:",
        buttonActivate: "Aktiviraj",
        buttonDeactivate: "Deaktiviraj",
        confirmationStatusChangeMessage:
          "Da li ste sigurni da želite da {{action}} korisnika?",
        toActivate: "aktivirate",
        toDeactivate: "deaktivirate",
        successfullyChangedStatus: "Uspešno promenjen status korisnika",
        unsuccessfullyChangedStatus: "Neuspešno promenjen status korisnika",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
      },
      licenses: {
        addButonForLicenses: "Dodaj novu licencu",
        updated: "Datum ažuriranja",
        issuedAtUtc: "Važenje od:",
        expiresAtUtc: "Važenje do:",
        endDate: "Važi do *",
        startDate: "Važi od *",
        updatedAtUtc: "Ažurirana",
        type: "Tip licence:",
        selectLicenseType: "Tip  licence *",
        chooseLicence: "Unesi licencu",
        licenseType: {
          trial: "Trial",
          full: "Full",
        },
        addNewLicense: "Dodaj licencu",
        editLicense: "Izmeni licencu",
        successfullyUpdated: "Licenca je uspešno ažurirana",
        successfullyAdded: "Licenca je uspešno dodata",
        status: "Status:",
        lastChanged: "Poslednje izmene:",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
        statusValid: "Važeća",
        statusInvalid: "Nevažeća",
        startDateRequired: "Potreban je datum početka važenja licence",
        endDateRequired: "Potreban je datum isetka licence",
        licenseTypeRequired: "Tip licence je obavezan",
        startAndEndNotGood:
          "Datum isteka licence ne može biti pre datuma početka",
        licenseDeletePrompt: "Da li ste sigurni da zelite da uklonite licencu?",
        licenseDeleted: "Uspešno obrisana licenca",
      },
      resources: {
        name: "Ime resursa",
        type: "Tip resursa",
        resourceType: {
          employee: "Zaposleni",
          equipment: "Osnovno sredstvo",
          premise: "Prostorija",
        },
        inputField: {
          label: {
            name: "Ime resursa",
            type: "Tip resursa",
          },
          placeholder: {
            name: "Unesi ime resursa",
            type: "Unesi tip resursa",
          },
        },
        status: "Status resursa",
        statusActive: "Aktivan",
        statusInactive: "Neaktivan",
        addNewResource: "Dodaj novi resurs",
        addResource: "Dodaj resurs",
        editResource: "Izmeni resurs",
        confirmationResourceDeletionMessage:
          "Da li ste sigurni da želite da uklonite resurs?",
        successfullyRemoved: "Uspešno uklonjen resurs",
        unsuccessfullyRemoved: "Neuspešno uklonjen resurs",
        successfullyAdded: "Uspešno dodat resurs",
        successfullyUpdated: "Uspešno ažuriran resurs",
        successfullyChangedStatus: "Uspešno promenjen status resursa",
        unsuccessfullyChangedStatus: "Neuspešno promenjen status resursa",
      },
    },
  },
  profile: {
    image: "Slika profila",
    chooseImage: "Izaberi sliku za profil",
    form: {
      step1: "Lični podaci",
      step2: "Adresa",
      step3: "Posao",
    },
    labels: {
      firstName: "Ime",
      lastName: "Prezime",
      gender: "Pol",
      city: "Grad",
      street: "Ulica",
      postalCode: "Poštanski broj",
      companyName: "Ime firme",
      companyAddress: "Adresa firme",
      yearsInCompany: "Broj godina u firmi",
    },
  },
  serviceCategory: {
    selectService: "Odaberite delatnost",
    addNewServiceCategory: "Dodaj novu delatnost",
    editServiceCategory: "Izmeni delatnost",
    inputField: {
      label: {
        name: "Delatnost *",
        description: "Oblast u delatnosti *",
      },
      placeholder: {
        name: "Unesite novu delatnost",
        description: "Unesite oblast u delatnosti",
      },
    },
  },
  user: {
    breadCrumbs: {
      placeholder: "Plejsholder",
    },
    deleteAccount: "Obriši nalog",
  },
  client: {
    breadCrumbs: {
      placeholder: "Plejsholder",
    },
  },
  footer: {
    left: "Termium <0>Copyright</0> © <0>2024</0>",
    companyName: "Tremium Software.",
    rights: "Sva prava zadržana.",
  },

  sidebar: {
    controlTable: "Kontrolna tabla",
    services: "Delatnosti",
    clients: "Klijenti",
    users: "Korisnici",
  },
  workingHours: {
    timeBeforeCancellationAllowed: "Rok za otkazivanje (h)",
    workingHoursTitle: "Radno vreme",
    bookingAllowed: "Dozvoljeno zakazivanje",
    bookingNotAllowed: "Booking Not Allowed",
    appointmentBeginning: "Početak termina na",
    workingDay: "Radni dani",
    workingHours: "Radno vreme od",
    notWorking: "Neradno",
    from: "Od *",
    to: "Do *",
    chooseTime: "Izaberi vreme",
    startAndEndNotGood: "Početno vreme mora biti pre krajnjeg",
    startTimeRequired: "Unesite početno vreme",
    endTimeRequired: "Unesite krajnje vreme",
    timeOverlap: "Izabrano vreme se preklapa sa postojecim",
    inputPositive: "Mora pozitivan broj",
  },
  days: {
    monday: "Ponedeljak",
    tuesday: "Utorak",
    wednesday: "Sreda",
    thursday: "Četvrtak",
    friday: "Petak",
    saturday: "Subota",
    sunday: "Nedelja",
    nonWorkingDay: "Neradno",
  },
  image: {
    error: "Greska pri učitavanju slike",
    loading: "Učitavanje...",
  },
  notification: {
    newNotification: "Stiglo vam je novo obaveštenje!",
    newNotifications: "Stigla su vam {{count}} nova obaveštenja!",
    noNotifications: "Nema obaveštenja",
  }
};
