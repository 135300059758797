import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CloseButton,
  CloseImgStyled,
  ModalImage,
} from "./FullImageModal.styled";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";

const FullImageModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <ModalImage src={props.imageUrl} alt={t("image.error")} />
      <CloseButton onClick={() => dispatch(unsetShownModal())}>
        <CloseImgStyled />
      </CloseButton>
    </>
  );
};

FullImageModal.propTypes = {
  imageUrl: PropTypes.string,
};

export default FullImageModal;
