import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Table from "components/Table/Table";
import { useGetPaginatedAppointmentsForClientQuery } from "features/appointmentSlice/appointmentApiSlice";
import { formatDate } from "util/dateHelpers";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import { randomIdGenerator } from "util/randomGenerator";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import { appointmentStatuses } from "constants/filters/statusConstants";
import { InputFieldContainer } from "components/Filter/FilterContainer.styled";
import { setCancelAppointmentModal } from "features/modal/modalSlice";
import inputTypes from "constants/inputTypes";
import { ClientCalendarContentStyled } from "./ClientCalendarContent.styled";
import FilterContainer from "components/Filter/FilterContainer";
import clientAppointmentsTableConstants from "constants/tableConstants/clientAppointmentsTableConstants";
import { removeFilter } from "util/filterHelper";
import filterValueToRemove from "constants/filters/filterValueToRemove";

const ClientCalendarContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mapRowDataFunction = (rowData) =>
    rowData.data?.appointments?.map?.((appointment) => {
      return {
        id: appointment?.id,
        userId: appointment?.user?.id,
        startDateTimeAppointment: appointment?.fromTimeUtc,
        endDateTimeAppointment: appointment?.toTimeUtc,
        date: formatDate(new Date(appointment.fromTimeUtc), "dd.MM.yyyy"),
        time:
          convertUTCToLocalTime(appointment.fromTimeUtc) +
          "-" +
          convertUTCToLocalTime(appointment.toTimeUtc) +
          "h",
        serviceName: appointment?.clientService?.name,
        userName:
          appointment?.user?.firstName + " " + appointment?.user?.lastName,
        price:
          appointment?.clientService?.price +
          appointment?.chosenAttributes?.reduce?.(
            (acc, attribute) => acc + attribute.price,
            0
          ) +
          "din",
        status: appointment?.status,
        allowedTime: appointment?.client?.timeBeforeCancellationAllowedInHours,
      };
    });

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters({
      ...filters,
    });
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"clientService.serviceName"}
          placeholder={"clientService.placeholderName"}
          onChange={(serviceName) =>
            setFilters((prevState) => ({
              ...prevState,
              serviceName: {
                id: serviceName,
                name: serviceName,
                valueToRemove: filterValueToRemove.SERVICE_NAME,
              },
            }))
          }
          value={filters?.serviceName?.name}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"appointments.filters.UserFirstName"}
          placeholder={"appointments.filters.inputUserFirstName"}
          onChange={(userFirstName) =>
            setFilters((prevState) => ({
              ...prevState,
              userFirstName: {
                id: userFirstName,
                name: userFirstName,
                valueToRemove: filterValueToRemove.USER_FIRST_NAME,
              },
            }))
          }
          value={filters?.userFirstName?.name}
        />
        <TextFilter
          label={"appointments.filters.UserLastName"}
          placeholder={"appointments.filters.inputUserLastName"}
          onChange={(userLastName) =>
            setFilters((prevState) => ({
              ...prevState,
              userLastName: {
                id: userLastName,
                name: userLastName,
                valueToRemove: filterValueToRemove.USER_LAST_NAME,
              },
            }))
          }
          value={filters?.userLastName?.name}
        />
      </InputFieldContainer>,
      <InputFieldContainer twoInputs key={randomIdGenerator()}>
        <TextFilter
          label={"appointments.filters.fromDate"}
          placeholder={"appointments.filters.fromDatePlaceholder"}
          type={inputTypes.DATE}
          onChange={(fromDate) =>
            setFilters((prevState) => ({
              ...prevState,
              fromDate: {
                id: fromDate,
                name:
                  t("common.from") +
                  " " +
                  formatDate(new Date(fromDate), "dd.MM.yyyy"),
                valueToRemove: filterValueToRemove.FROM_DATE,
              },
            }))
          }
          value={filters?.fromDate?.id}
        />
        <TextFilter
          label={"appointments.filters.toDate"}
          placeholder={"appointments.filters.toDatePlaceholder"}
          type={inputTypes.DATE}
          onChange={(toDate) =>
            setFilters((prevState) => ({
              ...prevState,
              toDate: {
                id: toDate,
                name:
                  t("common.to") +
                  " " +
                  formatDate(new Date(toDate), "dd.MM.yyyy"),
                valueToRemove: filterValueToRemove.TO_DATE,
              },
            }))
          }
          value={filters?.toDate?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"appointments.filters.status"}
          placeholder={"appointments.filters.statusPlaceholder"}
          defaultValue={"superAdmin.table.clients.filters.allStatuses"}
          onChange={(status) =>
            setFilters((prevState) => ({
              ...prevState,
              status: { ...status, valueToRemove: filterValueToRemove.STATUS },
            }))
          }
          value={filters?.status}
          items={appointmentStatuses}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  return (
    <ClientCalendarContentStyled>
      <FilterContainer
        filterComponents={filterComponents}
        saveFilters={saveFilters}
        isFilterButtonShown
        appliedFilters={appliedFilters}
        filters={filters}
        removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
      />
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientAppointmentsTableConstants}
        usePaginatedQuery={useGetPaginatedAppointmentsForClientQuery}
        rowBtnClick={(appointment) =>
          dispatch(setCancelAppointmentModal(appointment))
        }
        numberOfCharactersPerCell={30}
        appliedFilters={appliedFilters}
        clientId={props.clientId}
        t={t}
      />
    </ClientCalendarContentStyled>
  );
};

ClientCalendarContent.propTypes = {
  clientId: PropTypes.string,
};

export default ClientCalendarContent;
