import React, { useMemo, useState } from "react";
import MainLayout from "layouts/MainLayout";

import PageHeader from "components/PageHeader/PageHeader";
import Table from "components/Table/Table";
import { useGetPaginatedAppointmentsForUserQuery } from "features/appointmentSlice/appointmentApiSlice";
import userAppointmentsTableConstants from "constants/tableConstants/userAppointmentsTableConstants";
import { formatDate } from "util/dateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { ReactComponent as AppointmentIcon } from "assets/images/svg/appointments-icon-selected.svg";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import { randomIdGenerator } from "util/randomGenerator";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import {
  MessageContainer,
  NoAppointmentsMessageContainer,
  ScheduleAppointmentButton,
} from "./AppointmentsPage.styled";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import { appointmentStatuses } from "constants/filters/statusConstants";
import { InputFieldContainer } from "components/Filter/FilterContainer.styled";
import { setCancelAppointmentModal } from "features/modal/modalSlice";
import inputTypes from "constants/inputTypes";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import { removeFilter } from "util/filterHelper";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";

const AppointemntsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [isFilterButtonShown, setIsFilterButtonShown] = useState(false);
  const dispatch = useDispatch();

  const mapRowDataFunction = (rowData) => {
    let appointments = rowData.data?.appointments;
    if (filters?.status?.id === APPOINTMENT_STATUS.IN_PROGRESS) {
      appointments = appointments.filter(
        (appointment) =>
          appointment.status === APPOINTMENT_STATUS.RESERVED &&
          new Date(appointment?.fromTimeUtc) < new Date() &&
          new Date(appointment?.toTimeUtc) > new Date()
      );
    }
    return appointments.map?.((appointment) => {
      return {
        id: appointment?.id,
        userId: appointment?.user?.id,
        startDateTimeAppointment: appointment?.fromTimeUtc,
        endDateTimeAppointment: appointment?.toTimeUtc,
        date: formatDate(new Date(appointment.fromTimeUtc), "dd.MM.yyyy"),
        time:
          convertUTCToLocalTime(appointment.fromTimeUtc) +
          "-" +
          convertUTCToLocalTime(appointment.toTimeUtc) +
          "h",
        serviceName: appointment?.clientService?.name,
        clientName: appointment?.client?.fullName,
        price:
          appointment?.clientService?.price +
          appointment?.chosenAttributes?.reduce?.(
            (acc, attribute) => acc + attribute.price,
            0
          ) +
          "din",
        status: appointment?.status,
        allowedTime: appointment?.client?.timeBeforeCancellationAllowedInHours,
      };
    });
  };
  const handleEmptyTable = () => {
    return (
      <NoAppointmentsMessageContainer>
        <MessageContainer>
          {t("appointments.noAppointmentsMessage")}
        </MessageContainer>
        <ScheduleAppointmentButton
          value={t("common.scheduleAppointment")}
          onClick={() => {
            navigate(PAGES.CLIENTS.route);
          }}
        />
      </NoAppointmentsMessageContainer>
    );
  };

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters({
      ...filters,
    });
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"clientService.serviceName"}
          placeholder={"clientService.placeholderName"}
          onChange={(serviceName) =>
            setFilters((prevState) => ({
              ...prevState,
              serviceName: {
                id: serviceName,
                name: serviceName,
                valueToRemove: filterValueToRemove.SERVICE_NAME,
              },
            }))
          }
          value={filters?.serviceName?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"appointments.filters.client"}
          placeholder={"appointments.filters.inputClientName"}
          onChange={(clientName) =>
            setFilters((prevState) => ({
              ...prevState,
              clientName: {
                id: clientName,
                name: clientName,
                valueToRemove: filterValueToRemove.CLIENT_NAME,
              },
            }))
          }
          value={filters?.clientName?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer twoInputs key={randomIdGenerator()}>
        <TextFilter
          label={"appointments.filters.fromDate"}
          placeholder={"appointments.filters.fromDatePlaceholder"}
          type={inputTypes.DATE}
          onChange={(fromDate) =>
            setFilters((prevState) => ({
              ...prevState,
              fromDate: {
                id: fromDate,
                name:
                  t("common.from") +
                  " " +
                  formatDate(new Date(fromDate), "dd.MM.yyyy"),
                valueToRemove: filterValueToRemove.FROM_DATE,
              },
            }))
          }
          value={filters?.fromDate?.id}
        />
        <TextFilter
          label={"appointments.filters.toDate"}
          placeholder={"appointments.filters.toDatePlaceholder"}
          type={inputTypes.DATE}
          onChange={(toDate) =>
            setFilters((prevState) => ({
              ...prevState,
              toDate: {
                id: toDate,
                name:
                  t("common.to") +
                  " " +
                  formatDate(new Date(toDate), "dd.MM.yyyy"),
                valueToRemove: filterValueToRemove.TO_DATE,
              },
            }))
          }
          value={filters?.toDate?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"appointments.filters.status"}
          placeholder={"appointments.filters.statusPlaceholder"}
          defaultValue={"superAdmin.table.clients.filters.allStatuses"}
          onChange={(status) =>
            setFilters((prevState) => ({
              ...prevState,
              status: { ...status, valueToRemove: filterValueToRemove.STATUS },
            }))
          }
          value={filters?.status}
          items={appointmentStatuses}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<AppointmentIcon />}
        isFilterButtonShown={isFilterButtonShown}
        appliedFilters={appliedFilters}
        filterComponents={filterComponents}
        filters={filters}
        removeFilter={(valueToRemove) =>
          removeFilter(valueToRemove, setFilters)
        }
        saveFilters={saveFilters}
      />
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={userAppointmentsTableConstants}
        usePaginatedQuery={useGetPaginatedAppointmentsForUserQuery}
        rowBtnClick={(appointment) =>
          dispatch(setCancelAppointmentModal(appointment))
        }
        handleEmptyTable={handleEmptyTable}
        numberOfCharactersPerCell={30}
        appliedFilters={appliedFilters}
        setIsFilterButtonShown={(newValue) => setIsFilterButtonShown(newValue)}
        userId={user.id}
        t={t}
      />
    </MainLayout>
  );
};

export default AppointemntsPage;
