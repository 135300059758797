import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const MainContainer = styled.div`
  display: flex;
  padding: 16px;
  gap: 20px;
`;

export const LeftContainer = styled.div`
  display: flex;
  gap: 40px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 30%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
`;

export const GalleryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
  height: 275px;
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px 24px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
  height: 796.6px;
`;

export const ClientAdminCheckContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
