import React from "react";
import { NotificationBadgeStyled } from "./NotificationBadge.styled";
import { useSelector } from "react-redux";
import { selectUnreadNotificationsCount } from "features/notificationsSlice/notificationsSlice";

const NotificationBadge = () => {
  const numberOfMessages = useSelector(selectUnreadNotificationsCount);

  return (
    numberOfMessages > 0 && (
      <NotificationBadgeStyled>{numberOfMessages}</NotificationBadgeStyled>
    )
  );
};
export default NotificationBadge;
