export const userStatuses = [
  { id: true, name: "superAdmin.table.users.statusActive" },
  { id: false, name: "superAdmin.table.users.statusInactive" },
];

export const clientStatuses = [
  { id: "Active", name: "superAdmin.table.clients.statusActive" },
  { id: "Inactive", name: "superAdmin.table.clients.statusInactive" },
];

export const appointmentStatuses = [
  { id: 0, name: "appointments.appointmentStatus.reserved" },
  { id: 1, name: "appointments.appointmentStatus.cancelled" },
  { id: 2, name: "appointments.appointmentStatus.done" },
  { id: 3, name: "appointments.appointmentStatus.inProgress" },
];
