import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ArrowButton,
  GalleryContentContainer,
  ImagesContainer,
  ImagesContainerWrapper,
  LeftArrowContainer,
  LeftArrowStyled,
  RightArrowContainer,
  RightArrowStyled,
} from "./GalleryContent.styled";
import { useGetClientGalleryQuery } from "features/clients/clientsApiSlice";
import { ImageContainer } from "components/ImageGallery/ImageGallery.styled";
import ImageComponent from "components/ImageGallery/Image";

const GalleryContent = (props) => {
  const { data, isLoading } = useGetClientGalleryQuery(props?.client?.id);
  const galleryImages = isLoading ? [] : data.data.imageResponses;
  const listRef = useRef(null);

  const duplicatedImages = [
    ...galleryImages,
    ...galleryImages,
    ...galleryImages,
  ];

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const container = listRef.current;

    if (!container) return;

    const handleScroll = () => {
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;

      if (scrollLeft <= 0) {
        container.scrollLeft = scrollWidth / 3;
      } else if (scrollLeft + clientWidth >= scrollWidth) {
        container.scrollLeft = scrollWidth / 3;
      }
    };

    container.scrollLeft = container.scrollWidth / 3;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [duplicatedImages]);

  return (
    <>
      <GalleryContentContainer>
        {galleryImages.length >= 3 && (
          <LeftArrowContainer>
            <ArrowButton>
              <LeftArrowStyled onClick={scrollLeft} />
            </ArrowButton>
          </LeftArrowContainer>
        )}
        <ImagesContainerWrapper ref={listRef}>
          <ImagesContainer>
            {duplicatedImages.map((image, index) => (
              <ImageContainer key={index}>
                <ImageComponent imageId={image?.id} />
              </ImageContainer>
            ))}
          </ImagesContainer>
        </ImagesContainerWrapper>
        {galleryImages.length >= 3 && (
          <RightArrowContainer>
            <ArrowButton>
              <RightArrowStyled onClick={scrollRight} />
            </ArrowButton>
          </RightArrowContainer>
        )}
      </GalleryContentContainer>
    </>
  );
};

GalleryContent.propTypes = {
  client: PropTypes.any,
};

export default GalleryContent;
