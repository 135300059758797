import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Title,
  CloseButton,
  Modal,
  ActionButton,
  ButtonsContainer,
  QuestionMarkImg,
  CheckImg,
} from "./ChangeUserStatusModal.styled";
import { VARIANT } from "constants/buttonConstants";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { useChangeUserStatusMutation } from "features/user/usersApiSlice";
import { makeToastMessage } from "util/toastMessage";
import {
  useActivateClientMutation,
  useDeactivateClientMutation,
} from "features/clients/clientsApiSlice";
import authType from "constants/authType";
import { setStatus } from "features/clients/clientSlice";

const ChangeUserStatusModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changeUserStatus] = useChangeUserStatusMutation();
  const [activateClient] = useActivateClientMutation();
  const [deactivateClient] = useDeactivateClientMutation();

  const onActionUser = async () => {
    const response = await changeUserStatus(props.userId);
    response.data.isSuccess
      ? makeToastMessage(
          t("superAdmin.table.users.successfullyChangedStatus"),
          "success"
        )
      : makeToastMessage(
          t("superAdmin.table.users.unsuccessfullyChangedStatus"),
          "error"
        );
    dispatch(unsetShownModal());
  };

  const onActionClient = async () => {
    var response = null;
    if (props?.active == true) {
      response = await deactivateClient(props.userId);
      dispatch(setStatus(false));
    } else {
      response = await activateClient(props.userId);
      if (response.error) {
        dispatch(unsetShownModal());
        makeToastMessage(response.error.data.errors[0].message, "error");
      } else {
        dispatch(setStatus(true));
      }
    }

    response.data.isSuccess
      ? makeToastMessage(
          t("superAdmin.table.users.successfullyChangedStatus"),
          "success"
        )
      : makeToastMessage(
          t("superAdmin.table.users.unsuccessfullyChangedStatus"),
          "error"
        );
    dispatch(unsetShownModal());
  };

  const onClose = async () => {
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      {props.active ? <QuestionMarkImg /> : <CheckImg />}
      {props.userType == authType.USER ? (
        <>
          <Title>
            {t("superAdmin.table.users.confirmationStatusChangeMessage", {
              action: props.active
                ? t("superAdmin.table.users.toDeactivate")
                : t("superAdmin.table.users.toActivate"),
            })}
          </Title>
          <Divider>
            <ButtonsContainer>
              <CloseButton
                onClick={onClose}
                value={t(`common.cancel`)}
                variant={VARIANT.OUTLINED}
              />
              <ActionButton
                active={props.active}
                onClick={onActionUser}
                value={
                  props.active
                    ? t(`superAdmin.table.users.buttonDeactivate`)
                    : t(`superAdmin.table.users.buttonActivate`)
                }
              />
            </ButtonsContainer>
          </Divider>
        </>
      ) : (
        <>
          <Title>
            {t("superAdmin.table.clients.confirmationStatusChangeMessage", {
              action: props.active
                ? t("superAdmin.table.users.toDeactivate")
                : t("superAdmin.table.users.toActivate"),
            })}
          </Title>
          <Divider>
            <ButtonsContainer>
              <CloseButton
                onClick={onClose}
                value={t(`common.cancel`)}
                variant={VARIANT.OUTLINED}
              />
              <ActionButton
                active={props.active}
                onClick={onActionClient}
                value={
                  props.active
                    ? t(`superAdmin.table.users.buttonDeactivate`)
                    : t(`superAdmin.table.users.buttonActivate`)
                }
              />
            </ButtonsContainer>
          </Divider>
        </>
      )}
    </Modal>
  );
};

ChangeUserStatusModal.propTypes = {
  active: PropTypes.bool,
  userId: PropTypes.string,
  userType: PropTypes.any,
};

export default ChangeUserStatusModal;
