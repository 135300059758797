import React from "react";
import PropTypes from "prop-types";
import { GridContainer, RowContainer } from "./ListingServicesComponent.styled";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import { useNavigate, useParams } from "react-router-dom";
import Item from "./Item";

const ListingServicesComponent = (props) => {
  const navigate = useNavigate();
  const rows = [];
  for (let i = 0; i < props.items.length; i += props.itemsPerRow) {
    rows.push(props.items.slice(i, i + props.itemsPerRow));
  }

  const { serviceCategoryId } = useParams();

  const onClickSchedule = (service) => {
    const pathParams = {
      serviceCategoryId: serviceCategoryId,
      clientId: props?.clientId,
      clientServiceId: service.id,
    };
    const resolvedRoute = replaceInRoute(
      PAGES.SCHEDULE_APPOINTMENT.route,
      pathParams
    );
    navigate(resolvedRoute);
  };
  return (
    <GridContainer>
      {rows.map((row, rowIndex) => (
        <RowContainer key={rowIndex}>
          {row.map((item, itemIndex) => (
            <Item
              key={itemIndex}
              item={item}
              itemIndex={itemIndex}
              onClickSchedule={onClickSchedule}
            />
          ))}
        </RowContainer>
      ))}
    </GridContainer>
  );
};

ListingServicesComponent.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
  clientId: PropTypes.string,
};

export default ListingServicesComponent;
