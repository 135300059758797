import * as Yup from "yup";

export default Yup.object().shape({
  startDateUtc: Yup.date().required("register.inputRequired"),
  endDateUtc: Yup.date()
    .required("register.inputRequired")
    .min(
      Yup.ref("startDateUtc"),
      "superAdmin.table.licenses.startAndEndNotGood"
    ),
  resourceIds: Yup.array().min(1, "register.inputRequired"),
  clientServiceId: Yup.object().required("register.inputRequired"),
});
