export const removeFilter = (valueToRemove, setFilters) => {
  setFilters((oldFilters) => {
    const newFilters = Object.fromEntries(
      Object.entries(oldFilters).filter(
        ([, item]) => item.valueToRemove !== valueToRemove
      )
    );
    return newFilters;
  });
};
