import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { MessageIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import NotificationTable from "components/Notifications/NotificationTable";

const MessagesPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<MessageIconSelected />} />
      <NotificationTable />
    </MainLayout>
  );
};

export default MessagesPage;
