import { createSelector, createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
  },

  reducers: {
    addNotification: (state, action) => {
      state.notifications.push({ message: action.payload, read: false });
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    markAllAsRead: (state) => {
      state.notifications.forEach((notification) => {
        notification.read = true;
      });
    },
    markNotificationAsRead: (state, action) => {
        const notificationId = action.payload;
        const notification = state.notifications.find(
          (notif) => notif.id === notificationId
        );
        if (notification) {
          notification.read = true;
        }
      },
      clearNotifications: (state) => {
        state.notifications = [];
      },
    },
  });
  
  export const {
    addNotification,
    setNotifications,
    markAllAsRead,
    markNotificationAsRead,
    clearNotifications
  } = notificationsSlice.actions;

export const selectNotifications = (state) => state.notifications.notifications;

export const selectUnreadNotificationsCount = createSelector(
  selectNotifications,
  (notifications) =>
    notifications.filter((notification) => !notification.read).length
);

export default notificationsSlice.reducer;
