import { TableContainer } from "@mui/material";
import TableBodyContainer from "components/Table/TableBodyContainer";
import styled from "styled-components";

export const ServicesTableContainerStyled = styled(TableContainer)`
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  max-width: ${({ isClient }) => (isClient ? "100%" : "")};
  height: ${({ isClient }) => (isClient ? "100%" : "")};
  padding: ${({ isClient }) => (isClient ? "25px" : "0px")};
  table-layout: fixed;
`;

export const ServicesTableBodyContainer = styled(TableBodyContainer)``;
