import React, { useEffect } from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { UsersIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import UserPageContent from "components/SuperAdmin/UserPageContent/UserPageContent";
import { useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useGetUserQuery } from "features/user/usersApiSlice";
import { useDispatch } from "react-redux";
import { setUser } from "features/user/userSlice";
import { useIsLoading } from "hooks/useIsLoading";

const UserPage = () => {
  useIsLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { data, isLoading } = useGetUserQuery(params?.userId);
  const user = isLoading ? null : data?.data?.user;

  useEffect(() => {
    if (!isLoading) {
      dispatch(setUser({ ...user }));
    }
  }, [user]);

  const onClickBackBtn = () => {
    navigate(PAGES.USERS.route);
  };

  return (
    <MainLayout>
      <PageHeader leftIcon={<UsersIconSelected />} />
      <UserPageContent
        user={user}
        onClickBackBtn={onClickBackBtn}
        isSuperAdmin
      />
    </MainLayout>
  );
};

export default UserPage;
