import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AppointmentStatusDot,
  AppointmentStatusWrapper,
} from "./AppointmentStatus.styled";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";

const AppointmentStatus = (props) => {
  const { t } = useTranslation();

  const statusTextKeys = {
    [APPOINTMENT_STATUS.RESERVED]: props?.activeTextKey,
    [APPOINTMENT_STATUS.CANCELLED]: props?.inactiveTextKey,
    [APPOINTMENT_STATUS.DONE]: props?.invalidTextKey,
  };

  return (
    <AppointmentStatusWrapper
      currentActive={props?.currentActive}
      status={props?.status}
    >
      <AppointmentStatusDot
        currentActive={props?.currentActive}
        status={props?.status}
      />
      {props?.currentActive ? "U toku" : t(statusTextKeys[props?.status])}
    </AppointmentStatusWrapper>
  );
};

AppointmentStatus.propTypes = {
  status: PropTypes.number,
  activeTextKey: PropTypes.string,
  inactiveTextKey: PropTypes.string,
  invalidTextKey: PropTypes.string,
  currentActive: PropTypes.bool,
};

export default AppointmentStatus;
