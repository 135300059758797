import { Typography } from "@mui/material";
import { ReactComponent as ProfilePicture } from "../../assets/images/svg/super-admin-profile-picture.svg";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 10px 0px;
`;

export const UpperContainterWithProfilePicture = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
`;

const StyledImage = styled.img`
  display: flex;
  align-self: center;
  width: 40px;
  height: 40px;
`;

export const ProfilePictureContainer = styled(StyledImage).attrs({
  as: ProfilePicture,
})``;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  width: 100%;
  margin: 10px 0px 0px 0px;
`;

export const LabelIconContainer = styled.div`
  align-self: center;
  justify-self: center;
  padding-right: 5px;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
  font-family: "Baloo 2" !important;
  cursor: pointer;
`;
