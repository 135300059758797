export const licenseParams = (license) => {
  return {
    startDate: license?.startDateUtc ? license?.startDateUtc.split("T")[0] : "",
    endDate: license?.endDateUtc ? license?.endDateUtc.split("T")[0] : "",
    type: license?.type ?? "",
  };
};

export const FormikLicenseParams = {
  startDateUtc: "",
  endDateUtc: "",
  type: "",
};
