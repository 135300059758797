import React from "react";
import PropTypes from "prop-types";
import {
  HeaderProfileMenu,
  HeaderProfileMenuItem,
  HeaderProfilePopoverContentContainer,
} from "./HeaderProfilePopoverContent.styled";
import { useTranslation } from "react-i18next";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import { logoutUserRequest } from "request/loginRequest";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useAuth } from "hooks/useAuth";
import { useHubConnection } from "hooks/useHubConnection";
import { clearNotifications } from "features/notificationsSlice/notificationsSlice";

const HeaderProfilePopoverContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connection } = useHubConnection();
  const { isLoggedIn } = useAuth();
  
  const handleLogout = async () => {
    try {
      if (connection) {
        await connection.invoke("LogoutUserAsync");
      }
      await logoutUserRequest();
      dispatch(clearNotifications());
      dispatch(logOut());
      navigate(PAGES.LOGIN.route);
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  return (
    <HeaderProfilePopoverContentContainer>
      <HeaderProfileMenu>
        {isLoggedIn && (
          <HeaderProfileMenuItem onClick={handleLogout}>
            {t("common.logout")}
          </HeaderProfileMenuItem>
        )}
        {!isLoggedIn && (
          <>
            <HeaderProfileMenuItem to={PAGES.MIDDLEPAGE.route}>
              {t("register.registerTitle")}
            </HeaderProfileMenuItem>
            <HeaderProfileMenuItem to={PAGES.LOGIN.route}>
              {t("login.logIn")}
            </HeaderProfileMenuItem>
          </>
        )}
      </HeaderProfileMenu>
    </HeaderProfilePopoverContentContainer>
  );
};

HeaderProfilePopoverContent.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
};

export default HeaderProfilePopoverContent;
