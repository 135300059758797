import { setBreadcrumbs } from "features/app/appSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBreadcrumbs } from "util/routeHelpers";
import { useTranslation } from "react-i18next";

const useBreadcrumbs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(setBreadcrumbs(getBreadcrumbs(location.pathname, i18n)));
  }, [location, i18n.language]);

  return {};
};

export default useBreadcrumbs;
