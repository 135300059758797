import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomSelectField from "components/InputFields/CustomFields/CustomSelectField";

const ClientServiceField = (props) => {
  const { t } = useTranslation();
  const handleChange = (changedValue) => {
    props?.formik.setFieldValue("clientServiceId", changedValue);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomSelectField
        name={props.name}
        formik={props.formik}
        value={props?.formik?.values?.clientServiceId}
        placeholder={t(props.placeholder)}
        items={props?.clientServices
          ?.filter((x) => x.isActive)
          ?.map((clientService) => {
            return {
              id: clientService.id,
              name: clientService.name,
            };
          })}
        width={props.width}
        passValue={handleChange}
      />
    </Box>
  );
};

ClientServiceField.propTypes = {
  clientServices: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
};

export default ClientServiceField;
