import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const WithLoadingSpinner = (props) => {
  if (props.isLoading) {
    return <LoadingSpinner halfSize={props.halfSize} />;
  } else {
    return props.children;
  }
};

WithLoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  halfSize: PropTypes.bool,
};

export default WithLoadingSpinner;
