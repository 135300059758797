import React, { useState } from "react";
import {
  CategoryList,
  ServiceCategoriesContainer,
  TitleContainer,
  CardsContainer,
  MainContentContainer,
  PopularAndFavouritesClientsContainer,
  PopularClients,
  FavouriteClients,
  PopularAndFavouritesClientsContainerWithTitle,
  TitleContainerClients,
  OnlyPopularClientsContainer,
  OnlyFavouriteClientsContainer,
} from "./LandingPageContent.styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/useAuth";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import { useGetPaginatedAppointmentsForUserQuery } from "features/appointmentSlice/appointmentApiSlice";
import {
  useGetAllServiceCategoriesQuery,
  useGetClientsForServiceCategoryQuery,
} from "features/serviceCategory/serviceCategoryApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setServiceCategory } from "features/serviceCategory/serviceCategorySlice";
import ClientCard from "./ClientCard";
import { replaceInRoute } from "util/routeHelpers";
import { selectCurrentUser } from "features/auth/authSlice";
import {
  useGetClientByIdQuery,
  useGetFavouriteClientsQuery,
} from "features/clients/clientsApiSlice";
import HorizontalSlide from "components/HorizontalSlide/HorizontalSlide";
import { useIsLoading } from "hooks/useIsLoading";
import FirstSection from "./FirstSection/FirstSection";

const LandingPageContent = () => {
  useIsLoading();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAllServiceCategoriesQuery();
  const { isLoggedIn } = useAuth();
  const user = useSelector(selectCurrentUser);

  const onClickSelect = (category) => {
    if (!isDragging) {
      dispatch(setServiceCategory(category));
      navigate(PAGES.CLIENTS.route);
    }
  };

  const { data: appointments } = useGetPaginatedAppointmentsForUserQuery({
    id: user?.id,
    pageSize: 3,
  });

  const { data: clients } = useGetClientsForServiceCategoryQuery({
    pageNumber: 1,
    pageSize: 10,
    filters: {},
  });

  const { data: favouriteClientsData } = useGetFavouriteClientsQuery({
    userId: user?.id,
  });

  const paginatedClients = clients?.data?.clients;

  const paginatedAppointments = appointments?.data?.appointments;

  const onClick = (client) => {
    if (!isDragging) {
      const pathParams = {
        clientId: client?.id,
      };
      const resolvedRoute = replaceInRoute(PAGES.CLIENT.route, pathParams);
      navigate(resolvedRoute);
    }
  };

  const [isDragging, setIsDragging] = useState(false);

  const renderClientAdmin = () => {
    if (user?.clientId) {
      const { data: clientAdmin } = useGetClientByIdQuery(user?.clientId);
      return (
        <ClientCard
          onClick={() => onClick(clientAdmin?.data?.client)}
          client={clientAdmin?.data?.client}
          isClientAdmin
        />
      );
    }
  };

  return (
    <MainContentContainer>
      <FirstSection paginatedAppointments={paginatedAppointments} />
      <ServiceCategoriesContainer>
        <TitleContainer>{t("serviceCategory.selectService")}</TitleContainer>
        <CardsContainer>
          <HorizontalSlide
            setIsDragging={(newValue) => setIsDragging(newValue)}
          >
            <CategoryList>
              {data?.data?.serviceCategories?.map((category, index) => (
                <CategoryCard
                  key={index}
                  onClick={() => onClickSelect(category)}
                  imageId={category?.imageId}
                  name={category.name}
                  index={index}
                />
              ))}
            </CategoryList>
          </HorizontalSlide>
        </CardsContainer>
      </ServiceCategoriesContainer>
      <PopularAndFavouritesClientsContainerWithTitle>
        <PopularAndFavouritesClientsContainer>
          {isLoggedIn && (
            <OnlyFavouriteClientsContainer>
              <TitleContainerClients>
                {t("landingPage.favouriteClients")}
              </TitleContainerClients>
              <HorizontalSlide
                setIsDragging={(newValue) => setIsDragging(newValue)}
              >
                <FavouriteClients>
                  {renderClientAdmin()}
                  {favouriteClientsData?.data?.clients?.map?.(
                    (client, index) => (
                      <ClientCard
                        key={index}
                        index={index}
                        onClick={() => onClick(client)}
                        client={client}
                        isFavourite
                        userId={user?.id}
                      />
                    )
                  )}
                </FavouriteClients>
              </HorizontalSlide>
            </OnlyFavouriteClientsContainer>
          )}
          <OnlyPopularClientsContainer isLoggedIn={isLoggedIn}>
            <TitleContainerClients>
              {t("landingPage.popularClients")}
            </TitleContainerClients>
            <HorizontalSlide
              setIsDragging={(newValue) => setIsDragging(newValue)}
            >
              <PopularClients>
                {paginatedClients?.map((client, index) => (
                  <ClientCard
                    key={index}
                    index={index}
                    onClick={() => onClick(client)}
                    client={client}
                  />
                ))}
              </PopularClients>
            </HorizontalSlide>
          </OnlyPopularClientsContainer>
        </PopularAndFavouritesClientsContainer>
      </PopularAndFavouritesClientsContainerWithTitle>
    </MainContentContainer>
  );
};

LandingPageContent.propTypes = {
  theme: PropTypes.any,
};

export default LandingPageContent;
