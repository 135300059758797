import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StyledEngineProvider } from "@mui/material";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import GlobalStyle from "components/GlobalStyles/GlobalStyles";
// import { HOME_PAGE } from "constants/pages";
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "features/auth/authSlice";
import { UserProvider } from "context/UserContext";
import { MainContainer } from "layouts/MainLayout.styled";
import Modal from "components/Modals/Modal";
import { LanguageCodes } from "constants/languages";
import { useNotifications } from "hooks/useNotifications";

const App = () => {
  const { i18n } = useTranslation();
  useBreadcrumbs();
  useNotifications();
  // const navigate = useNavigate();

  // const auth = useSelector(selectCurrentToken);

  useEffect(() => {
    let lang = authScopeStringGetHelper(LANGUAGE);

    if (!Object.values(LanguageCodes).includes(lang)) {
      lang = LanguageCodes.SR;
      localStorage.setItem(LANGUAGE, lang);
    }

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  // useEffect(() => {
  //   if (auth !== null) {
  //     navigate(HOME_PAGE, { replace: true });
  //   }
  // }, []);

  return (
    <UserProvider>
      <Helmet>
        <title>{i18next.t("app.title")}</title>
      </Helmet>
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <ToastContainer bodyClassName="ToastBody" />
        <MainContainer>
          <Modal />
          <AppRoutes />
        </MainContainer>
      </StyledEngineProvider>
    </UserProvider>
  );
};

export default App;
