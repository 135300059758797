import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { UsersIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import UserPageContent from "components/SuperAdmin/UserPageContent/UserPageContent";
import { useGetUserQuery } from "features/user/usersApiSlice";
import { useSelector } from "react-redux";
import { useIsLoading } from "hooks/useIsLoading";
import { selectCurrentUser } from "features/auth/authSlice";

const UserAccountPage = () => {
  useIsLoading();
  const currentUser = useSelector(selectCurrentUser);
  const { data, isLoading } = useGetUserQuery(currentUser.id);
  const user = isLoading ? null : data?.data?.user;
  return (
    <MainLayout>
      <PageHeader leftIcon={<UsersIconSelected />} />
      <UserPageContent user={user} myAccountFlag />
    </MainLayout>
  );
};

export default UserAccountPage;
