import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import randomDataReducer from "../features/randomData/randomDataSlice";
import clientReducer from "./register/registerClientSlice";
import clientInfoReducer from "./clients/clientSlice";
import clientLogoReducer from "./clients/clientLogoSlice";
import serviceCategoryReducer from "./serviceCategory/serviceCategorySlice";
import clientServiceReducer from "./clientService/clientServiceSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./app/appSlice";
import accessTokensMiddleware from "store/middleware/accessTokensMiddleware";
import { serviceCategoriesApi } from "./serviceCategory/serviceCategoryApiSlice";
import userReducer from "./register/userRegisterSlice";
import { registerApiSlice } from "./register/registerApiSlice";
import modalReducer from "features/modal/modalSlice";
import sidebarReducer from "features/sidebar/sidebarSlice";
import clientObjectReducer from "features/clients/clientSlice";
import userObjectReducer from "features/user/userSlice";
import loadingReducer from "store/reducers/loading/loadingReducer";
import loadingMiddleware from "store/middleware/loadingMiddleware";
import notificationsReducer from "./notificationsSlice/notificationsSlice";

const authPersistConfig = {
  key: "auth",
  storage,
};

const notificationsPersistConfig = {
  key: "notifications",
  storage,
};

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistReducer(authPersistConfig, authReducer),
    randomData: randomDataReducer,
    client: clientReducer,
    app: appReducer,
    sc: serviceCategoriesApi.reducer,
    user: userReducer,
    rg: registerApiSlice.reducer,
    modal: modalReducer,
    sidebar: sidebarReducer,
    clientInfo: clientInfoReducer,
    clientLogo: clientLogoReducer,
    clientService: clientServiceReducer,
    clientObject: clientObjectReducer,
    userObject: userObjectReducer,
    serviceCategory: serviceCategoryReducer,
    loading: loadingReducer,
    notifications: persistReducer(notificationsPersistConfig, notificationsReducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(apiSlice.middleware)
      .concat(accessTokensMiddleware)
      .concat(loadingMiddleware),
  devTools: true,
});

export const persistor = persistStore(store);
