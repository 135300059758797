import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";
import { useTranslation } from "react-i18next";

export const TextFilter = (props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    props?.onChange(event.target.value);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <CustomTextField
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={t(props.placeholder)}
        minNumValue={props?.minNumValue}
        formik={props.formik}
        width={props.width}
        onChange={handleChange}
        helperText={props?.helperText}
      />
    </Box>
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  helperText: PropTypes.string,
  minNumValue: PropTypes.number,
};

TextFilter.defaultProps = {};

export default TextFilter;
