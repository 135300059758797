import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    startTime: Yup.date().required(t("workingHours.startTimeRequired")),
    endTime: Yup.date()
      .required(t("workingHours.endTimeRequired"))
      .test(
        "is-after-start",
        t("workingHours.startAndEndNotGood"),
        function (value) {
          const { startTime } = this.parent;
          return !startTime || !value || startTime < value;
        }
      ),
  });
};
