import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { makeToastMessage } from "util/toastMessage";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  CancelButton,
  DeleteButton,
  LowerContainerWrapper,
  Modal,
  QuestionMarkIconStyled,
  TitleStyled,
  UpperContainerWrapper,
} from "./CancelAppointmentModal.styled";
import { useCancelAppointmentMutation } from "features/appointmentSlice/appointmentApiSlice";
import { TEXTVARIANT } from "constants/textConstants";
import toastNotificationType from "constants/toastNotificationType";

export const LicenseDeleteModal = (props) => {
  const { t } = useTranslation();
  const [cancelAppointment] = useCancelAppointmentMutation();
  const dispatch = useDispatch();

  const handleCancel = async () => {
    const response = cancelAppointment({
      appointmentId: props?.appointment?.id,
      userId: props?.appointment?.userId,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("appointments.cancelAppointmentResponse"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <UpperContainerWrapper>
        <QuestionMarkIconStyled />
        <TitleStyled variant={TEXTVARIANT.h3}>
          {t("appointments.cancelAppointmentQuestion")}
        </TitleStyled>
      </UpperContainerWrapper>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.quit")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <DeleteButton value={t("common.cancel")} onClick={handleCancel} />
      </LowerContainerWrapper>
    </Modal>
  );
};

LicenseDeleteModal.propTypes = {
  appointment: PropTypes.any,
};

export default LicenseDeleteModal;
