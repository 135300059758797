import styled from "styled-components";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { cardColors } from "themes/secondaryTheme/secondaryThemeColors";

export const CategoryCardContainer = styled.div`
  border-radius: 12px;
  padding: 10px 24px;
  gap: 16px;
  display: flex;
  align-items: center;
  width: 240px;
  height: 130px;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.1)};
  background-color: ${(props) =>
    cardColors[props?.index % cardColors?.length]?.background};

  &:hover {
    transform: translateY(-3px);
  }
`;

export const ImageContainer = styled.div`
  width: 32px;
  height: 32px;
`;

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 144px;
  gap: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const StyledCardText = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  width: 144px;
  text-align: left;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => cardColors[props?.index % cardColors?.length]?.text};
`;

export const LineContainer = styled.div`
  width: 136px;
  height: 1px;
  background-color: ${(props) =>
    cardColors[props?.index % cardColors?.length]?.text};
`;
