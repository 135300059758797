import { Typography } from "@mui/material";
import { RightArrowStyled } from "components/ClientPanelForUsers/GalleryContent/GalleryContent.styled";
import styled from "styled-components";

export const ClientCardWrapper = styled.div`
  position: relative;
`;
export const ClientCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  width: 200px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
  height: auto;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  position: relative;
`;

export const ImageContainer = styled.div`
  height: 112px;
  width: 100%;
`;

export const CardImage = styled.img`
  border-radius: 12px 12px 0 0;
  height: 112px;
  width: 100%;
  object-fit: cover;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px;
`;

export const ClientName = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.darkBlue};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const ClientServiceCategoryName = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props?.theme?.colors?.darkBlue};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const ClientArrowStyled = styled(RightArrowStyled)`
  display: flex;
  align-self: flex-end;
  margin-top: 8px;
`;

export const AdminMark = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1000;
`;
