import styled from "styled-components";
import { ReactComponent as QuestionMarkIcon } from "assets/images/svg/question-mark-icon.svg";

import { hexToRGB } from "util/colorHelper";
import {
  ActionButton,
  CancelActionButton,
  UpperContainer,
} from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal.styled";
import { LowerContainer } from "components/RightAuthContent/RightAuthContent.styled";
import { Typography } from "@mui/material";

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  width: 460px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const UpperContainerWrapper = styled(UpperContainer)`
  height: 194px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const QuestionMarkIconStyled = styled(QuestionMarkIcon)`
  height: 56px;
  width: 56px;
`;

export const CancelButton = styled(CancelActionButton)`
  border-color: ${(props) => props?.theme?.colors?.text3};
  font-size: 14px;
`;

export const DeleteButton = styled(ActionButton)`
  font-size: 14px;
  background-color: ${(props) => props?.theme?.colors?.errorColor};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.errorColorHover};
  }
`;

export const LowerContainerWrapper = styled(LowerContainer)`
  flex-direction: row;
  gap: 10px;
  height: 97px;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

export const TitleStyled = styled(Typography)`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
`;
