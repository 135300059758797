import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
  InputFieldsContainer,
} from "./LicenceAddEditModal.styled";
import { InputFieldContainer } from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import TypeDropdownField from "./InputFields/TypeDropdownField";
import { licenseParams } from "initialValues/licenseParams";
import licenseValidation from "validations/licenseValidation";
import { makeToastMessage } from "util/toastMessage";
import {
  CloseIcon,
  TitleContainer,
} from "../ServiceCategoryEditAddModal/ServiceCategoryEditAddModal.styled";
import { unsetShownModal } from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import {
  useAddLicenseMutation,
  useUpdateLicenseMutation,
} from "features/clients/clientsApiSlice";
import DateField from "./InputFields/DateField";
import { formatDateForInput } from "util/helpers/dateHelpers";

const LicenseEditAddModal = (props) => {
  const { t } = useTranslation();
  const [addLicense] = useAddLicenseMutation();
  const [updateLicense] = useUpdateLicenseMutation();
  const dispatch = useDispatch();

  const setDateWithCurrentTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    date.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
    return date.toISOString();
  };

  const handleSubmit = async () => {
    const data = {
      startDateUtc: setDateWithCurrentTime(formik.values.startDate),
      endDateUtc: setDateWithCurrentTime(formik.values.endDate),
      licenseType: formik.values.type,
      clientId: props?.clientId,
    };

    let response = null;

    if (props?.data) {
      response = await updateLicense({
        id: props?.data?.id,
        data,
      });
    } else {
      response = await addLicense(data);
    }

    if (response?.error) {
      makeToastMessage(response.error.data.errors[0].message, "error");
    } else {
      makeToastMessage(
        props?.data
          ? t("superAdmin.table.licenses.successfullyUpdated")
          : t("superAdmin.table.licenses.successfullyAdded"),
        "success"
      );
      dispatch(unsetShownModal());
    }
  };

  const editFlag = useMemo(() => props?.data != null, [props?.data]);

  const formik = useFormik({
    initialValues: licenseParams(props?.data),
    validationSchema: licenseValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>
            {editFlag
              ? t("superAdmin.table.licenses.editLicense")
              : t("superAdmin.table.licenses.addNewLicense")}
          </Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
        <InputFieldsContainer>
          <InputFieldContainer>
            <DateField
              value={formatDateForInput(formik?.values?.startDateUtc)}
              formik={formik}
              label="superAdmin.table.licenses.startDate"
              name="startDate"
              onChange={formik.handleChange}
              helperText={formik.errors.startDate}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <DateField
              value={formatDateForInput(formik?.values?.endDateUtc)}
              formik={formik}
              label="superAdmin.table.licenses.endDate"
              name="endDate"
              onChange={formik.handleChange}
              helperText={formik.errors.endDate}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <TypeDropdownField
              value={formik?.values?.type}
              formik={formik}
              label="superAdmin.table.licenses.selectLicenseType"
              name="type"
              onChange={formik.handleChange}
              helperText={formik.errors.type}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t("common.quit")}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={editFlag ? t("common.save") : t("common.add")}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

LicenseEditAddModal.propTypes = {
  data: PropTypes.object,
  clientId: PropTypes.any,
};

export default LicenseEditAddModal;
