import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Title,
  CloseButton,
  StyledQuestionMarkImg,
  Modal,
  ActionButton,
  ButtonsContainer,
} from "./DeleteResourceModal.styled";
import { VARIANT } from "constants/buttonConstants";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { useDeleteClientResourceMutation } from "features/clients/clientsApiSlice";

const DeleteResourceModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteResource] = useDeleteClientResourceMutation();
  const onAction = async () => {
    const response = await deleteResource({
      clientId: props.clientId,
      resourceId: props?.resourceId,
    });
    response?.data?.isSuccess
      ? makeToastMessage(
          t("superAdmin.table.resources.successfullyRemoved"),
          toastNotificationType.SUCCESS
        )
      : makeToastMessage(
          response.error.data.errors[0].message,
          toastNotificationType.ERROR
        );
    dispatch(unsetShownModal());
  };

  const onClose = async () => {
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <StyledQuestionMarkImg />
      <Title>
        {t("superAdmin.table.resources.confirmationResourceDeletionMessage")}
      </Title>
      <Divider>
        <ButtonsContainer>
          <CloseButton
            onClick={onClose}
            value={t(`common.cancel`)}
            variant={VARIANT.OUTLINED}
          />
          <ActionButton onClick={onAction} value={t(`common.delete`)} />
        </ButtonsContainer>
      </Divider>
    </Modal>
  );
};

DeleteResourceModal.propTypes = {
  clientId: PropTypes.string,
  resourceId: PropTypes.string,
};

export default DeleteResourceModal;
