import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  ActionButton,
  LeaveButton,
  LowerContainer,
  Modal,
  StyledQuestionMarkImg,
  Title,
  UpperContainer,
} from "./AddClientsToFavoritesModal.styled";
import { useSubscribeUserToClientMutation } from "features/clients/clientsApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";

const AddClientsToFavoritesModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [subscribeToClient] = useSubscribeUserToClientMutation();

  const addToFavorites = () => {
    subscribeToClient({ userId: props?.userId, clientId: props?.clientId });
    cancel();
    makeToastMessage(
      t("scheduleAppointment.clientAddedToFavourites"),
      toastNotificationType.SUCCESS
    );
  };

  const cancel = () => {
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <UpperContainer>
        <StyledQuestionMarkImg />
        <Title>{t("scheduleAppointment.addClientToFav")}</Title>
      </UpperContainer>
      <LowerContainer>
        <LeaveButton value={t("common.no")} onClick={() => cancel()} />
        <ActionButton
          value={t("common.yes")}
          onClick={() => addToFavorites()}
        />
      </LowerContainer>
    </Modal>
  );
};

AddClientsToFavoritesModal.propTypes = {
  userId: PropTypes.string,
  clientId: PropTypes.string,
};

export default AddClientsToFavoritesModal;
