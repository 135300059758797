import React from "react";
import PropTypes from "prop-types";
import {
  AddButtonContainer,
  HeaderContainer,
  LabelContainer,
  MainContainer,
  UpperContainterWithProfilePicture,
  StyledTypography,
  LabelIconContainer,
} from "./PageHeader.styled";
import AddNewItemButton from "../SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import HeaderProfileBar from "components/Header/HeaderProfileBar/HeaderProfileBar";
import { useAuth } from "hooks/useAuth";
import { useLocation, useParams } from "react-router-dom";
import { generateBreadcrumbsFromPath, replaceInRoute } from "util/routeHelpers";
import { SingleRightArrowIconStyled } from "components/ClientEditTab/ClienEditTab.styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PAGES } from "constants/pages";
import FilterContainer from "components/Filter/FilterContainer";
import { selectBreadcrumbNames } from "store/selectors/breadcrumbsSelectors";
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/LanguageComponent/LanguageSelector ";

export const PageHeader = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const params = useParams();
  const breadcrumbNamesSelector = selectBreadcrumbNames(Object.keys(params));
  const breadcrumbNames = useSelector(breadcrumbNamesSelector);

  const breadcrumbs = generateBreadcrumbsFromPath(location.pathname).filter(
    (x) => x.show
  );

  const getNestedProperty = (obj, keyPath) => {
    return keyPath.split(".").reduce((acc, key) => {
      return acc && acc[key] !== undefined ? acc[key] : undefined;
    }, obj);
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    navigate(
      replaceInRoute(getNestedProperty(PAGES, breadcrumb.i18nKey).route, params)
    );
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <UpperContainterWithProfilePicture>
          <LabelContainer>
            <LabelIconContainer>{props?.leftIcon}</LabelIconContainer>
            <StyledTypography>{props?.pageTitle}</StyledTypography>
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <React.Fragment key={index}>
                  <StyledTypography
                    onClick={() => handleBreadcrumbClick(breadcrumb)}
                  >
                    {breadcrumb?.titleRenderer
                      ? breadcrumb.titleRenderer(t(breadcrumbNames?.shift()))
                      : t(breadcrumb.title)}
                  </StyledTypography>
                  {index < breadcrumbs.length - 1 && (
                    <SingleRightArrowIconStyled />
                  )}
                </React.Fragment>
              );
            })}
          </LabelContainer>
          <HeaderProfileBar isLoggedIn={isLoggedIn} />
          <LanguageSelector />
        </UpperContainterWithProfilePicture>
        {props.isAddButtonShown && (
          <AddButtonContainer>
            <AddNewItemButton
              value={props.buttonText}
              onClick={props.onClick}
            />
          </AddButtonContainer>
        )}
        <FilterContainer
          filterComponents={props?.filterComponents}
          saveFilters={props?.saveFilters}
          isFilterButtonShown={props?.isFilterButtonShown}
          appliedFilters={props?.appliedFilters}
          filters={props?.filters}
          removeFilter={props?.removeFilter}
        />
      </HeaderContainer>
    </MainContainer>
  );
};

PageHeader.propTypes = {
  leftIcon: PropTypes.node,
  pageTitle: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  isAddButtonShown: PropTypes.bool,
  isFilterButtonShown: PropTypes.bool,
  filterComponents: PropTypes.array,
  appliedFilters: PropTypes.array,
  filters: PropTypes.array,
  saveFilters: PropTypes.func,
  removeFilter: PropTypes.func,
};

export default PageHeader;
