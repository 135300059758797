import React from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import {
  CancelButton,
  DeleteButton,
  LowerContainerWrapper,
  TrashIconStyled,
  UpperContainerWrapper,
} from "./ClientServiceDeleteModal.styled";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  Modal,
  Title,
} from "components/ClientEditTab/ClientContentTab/TabContent/Resource/DeleteResourceModal/DeleteResourceModal.styled";

export const ClientServiceDeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal>
      <UpperContainerWrapper>
        <TrashIconStyled />
        <Title>{t("clientService.serviceDeletePrompt")}</Title>
      </UpperContainerWrapper>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.cancel")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <DeleteButton value={t("common.delete")} onClick={props?.onDelete} />
      </LowerContainerWrapper>
    </Modal>
  );
};

ClientServiceDeleteModal.propTypes = {
  onDelete: PropTypes.func,
};
