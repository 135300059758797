import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, MenuItem } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { useTranslation } from "react-i18next";
import CustomSelectFieldStyled, {
  DownArrowStyled,
  menuProps,
} from "components/InputFields/CustomFields/CustomSelectField.styled";

export const SelectFilter = (props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);
  const [items, setItems] = useState([]);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.onChange(event.target.value);
  };
  useEffect(() => {
    if (props.value != null) {
      setSelectedValue(props.value);
    }
  }, [props.value]);

  const { data, isLoading } = props.items ?? props.useQuery();

  useEffect(() => {
    if (!isLoading && !props.items) {
      setItems(props?.mapDataFunction(data));
    }
  }, [isLoading, data]);

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <CustomSelectFieldStyled
        name={props.name}
        value={selectedValue}
        displayEmpty
        onChange={handleChange}
        renderValue={(selected) => {
          if (!selected) {
            return <label>{t(props.placeholder)}</label>;
          }
          return t(selected?.name);
        }}
        IconComponent={DownArrowStyled}
        MenuProps={menuProps}
        selectedValueFlag={selectedValue}
      >
        <MenuItem key={null} value={{ id: null, name: t(props.defaultValue) }}>
          {t(props.defaultValue)}
        </MenuItem>
        {(props.items || items)?.map?.((item) => {
          return (
            <MenuItem key={item.id} value={item}>
              {t(item.name)}
            </MenuItem>
          );
        })}
      </CustomSelectFieldStyled>
    </Box>
  );
};

SelectFilter.propTypes = {
  onChange: PropTypes.func,
  useQuery: PropTypes.func,
  mapDataFunction: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

SelectFilter.defaultProps = {};

export default SelectFilter;
