import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "assets/images/svg/edit-icon.svg";
import { ReactComponent as TrashIcon } from "assets/images/svg/table-row-delete-icon.svg";
import { hexToRGB } from "util/colorHelper";

export const ClientServiceTabContentStyled = styled(Box)`
  width: 100%;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  background-color: ${(props) => props?.theme?.colors?.white};
  padding-right: 10px;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  gap: 5px;
`;

export const EditIconStyled = styled(EditIcon)`
  fill: ${(props) => props?.theme?.colors?.black};
  stroke: ${(props) => props?.theme?.colors?.black};
  cursor: pointer;
`;

export const TrashIconStyled = styled(TrashIcon)`
  cursor: pointer;
`;
