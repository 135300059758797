import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  DownArrowIconStyled,
  FilterButtonContainer,
  FilterButtonContainerWrapper,
} from "./FilterButton.styled";
import { ReactComponent as FilterIcon } from "assets/images/svg/filter-icon.svg";
import { useTranslation } from "react-i18next";

export const FilterButton = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <FilterButtonContainerWrapper ref={ref}>
      <FilterButtonContainer
        onClick={props.onClick}
        value={t(props.value)}
        startIcon={<FilterIcon />}
        endIcon={<DownArrowIconStyled />}
        disableRipple
      />
    </FilterButtonContainerWrapper>
  );
});

FilterButton.displayName = "FilterButton";

FilterButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

FilterButton.defaultProps = {
  value: "common.filter",
};

export default FilterButton;
