import styled from "styled-components";
import { Select, MenuItem } from "@mui/material";
import { ReactComponent as DownArrow } from "assets/images/svg/down-arrow.svg";

export const CustomSelectFieldStyled = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 16px;
}
width: 40px;
height: 40px;
margin-left: 20px;
background-color: ${({ theme }) => theme?.colors?.darkBlue};
border-radius: 16px;

  & .MuiInputBase-input {
    padding-left: 15%;
    color: ${({ theme }) => theme?.colors?.white};
    white-space: normal;
    overflow: visible;
    text-overflow: clip; 
  }

  & .MuiSelect-icon {
    top: 45%;
    right: 5px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
`;

export const DownArrowStyled = styled(DownArrow)`
  width: 10px;
  height: 10px;
  fill: ${(props) => props?.theme?.colors?.white};
  stroke: ${(props) => props?.theme?.colors?.white};
`;

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      maxWidth: 100,
    },
  },
};

export default CustomSelectFieldStyled;
