import CityField from "components/RegistrationContent/InputFields/CityField";
import styled from "styled-components";
import { Avatar, Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import LongDashStyled from "components/LongDash/LongDash.styled";
import CustomInputField from "components/InputFields/CustomFields/CustomInputField";
import Button from "components/Button/Button";

export const MainContainer = styled.div`
  display: flex;
  gap: 35px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 16px;
  gap: 25px;
  box-shadow: 2px 2px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.3)};
  width: 327px;
  height: fit-content;
  background-color: ${(props) => props?.theme?.colors?.white};
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
`;

export const SecondStatusContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: center;
`;

export const StatusText = styled(Typography)`
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: ${(props) =>
    props.active
      ? props?.theme?.colors?.statusActiveButton
      : props?.theme?.colors?.statusInactiveButton};
  transition: 0.3s ease;
`;

export const StatusButtonContainer = styled.div`
  width: 36px;
  height: 20px;
  padding: 2px;
  background-color: ${(props) =>
    props.active
      ? props?.theme?.colors?.statusActiveButton
      : props?.theme?.colors?.statusInactiveButton};
  display: flex;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  border-radius: 12px;
  cursor: pointer;
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 18px;
  transition: 0.3s ease;
`;

export const StatusAndImageAndNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0px 8px;
`;

export const ImageAndNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0px 8px;
`;

export const LongDash = styled(LongDashStyled)`
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.1)};
  height: 2px;
`;

export const AvatarStyled = styled(Avatar)`
  width: 120px;
  height: 120px;
  border-radius: 90px;
  align-self: center;
  font-weight: 700;
  font-size: 42px;
  line-height: 114px;
  background-color: ${(props) => props?.theme?.colors?.serviceChipColor};
  color: ${(props) => props?.theme?.colors?.serviceChipTextColor};
`;

export const NameContainer = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
`;

export const ActiveCircle = styled.div`
  width: 50%;
  height: 100%;
  border-radius: 90px;
  background-color: ${(props) => props?.theme?.colors?.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionContainer = styled.div`
  width: fit-content;
`;

export const CityFieldStyled = styled(CityField)``;

export const CustomInputFieldStyled = styled(CustomInputField)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid ${(props) => props?.theme?.colors?.clientPageFormBorder};
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  border-radius: 24px;
  background-color: ${(props) => props?.theme?.colors?.white};
  height: fit-content;
  width: 100%;
  padding: 16px;
`;

export const DeleteAndStatusButtonContainer = styled.div`
  display: flex;
  gap: 35px;
`;

export const SaveButton = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
  }
  &.Mui-disabled {
    background-color: ${(props) => props?.theme?.colors?.disabledColor};
  }
  max-height: 33px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 35px;
`;

export const DeleteAccountButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.statusInactiveButton};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.statusInactiveButton};
  }
  max-height: 33px;
`;
