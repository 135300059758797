import React from "react";
import PropTypes from "prop-types";
import WithPermissions from "../WithPermissions";
import { USER_ROLES } from "constants/userRoles";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";

const WithClientPermission = (props) => {
  const user = useSelector(selectCurrentUser);
  return (
    <WithPermissions roles={[USER_ROLES.CLIENT_ADMIN.name]}>
      {user?.clientId === props?.clientId ||
      user?.role?.includes(USER_ROLES.SUPER_ADMIN.name) ? (
        props.children
      ) : (
        <></>
      )}
    </WithPermissions>
  );
};

WithClientPermission.propTypes = {
  children: PropTypes.node,
  clientId: PropTypes.string,
};

export default WithClientPermission;
