import { ERROR, FETCH, SUCCESS } from "../actions/actionHelpers";
import { addLoader, removeLoader } from "../actions/app/appActions";
import rtkQueryActionTypes from "constants/rtkQueryActionTypes";

const promiseTypes = [FETCH];

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    try {
      const promiseType = promiseTypes.find((promiseType) =>
        action.type.includes(promiseType)
      );
      if (promiseType) {
        dispatch(addLoader(action.type));
        return next(action);
      }
      if (action.type === rtkQueryActionTypes.PENDING) {
        dispatch(addLoader(action.meta.arg.endpointName));
        return next(action);
      }

      if (action.type.includes(SUCCESS) || action.type.includes(ERROR)) {
        dispatch(removeLoader(action.type));
        return next(action);
      }

      if (
        action.type === rtkQueryActionTypes.FULFILLED ||
        action.type === rtkQueryActionTypes.REJECTED
      ) {
        dispatch(removeLoader(action.meta.arg.endpointName));
        return next(action);
      }
    } catch (e) {
      console.log("wrong action", action);
    }
    next(action);
  };
