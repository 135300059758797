import React, { useState, useEffect } from "react";
import { Table as TableComponent } from "@mui/material";
import TableFooterControls from "./TableFooterPagination";
import {
  TableFooterWrapper,
  TableContainerStyled,
  TableWrapper,
} from "./Table.styled";
import TableBodyContainer from "./TableBodyContainer";
import TableHeader from "./TableHeadContainer";
import PropTypes from "prop-types";
import { useIsLoading } from "hooks/useIsLoading";

const Table = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    props?.startingNumberOfItemsPerPage
  );
  const [totalRows, setTotalRows] = useState(0);

  const { data: paginatedData, isLoading } =
    props.items ??
    props.usePaginatedQuery({
      pageNumber: page,
      pageSize: rowsPerPage,
      filters: props?.appliedFilters,
      id: props?.clientId || props?.userId,
      serviceCategoryId: props?.serviceCategoryId,
      clientId: props?.clientId,
    });

  useIsLoading(isLoading);

  useEffect(() => {
    if (paginatedData && !props.items) {
      setRows(props.mapRowDataFunction(paginatedData));
      setTotalRows(paginatedData.totalCount || 0);
      if (
        paginatedData.totalCount !== 0 ||
        Object.entries(props?.appliedFilters || {}).length !== 0
      ) {
        props.setIsFilterButtonShown?.(true);
      } else {
        props?.setIsFilterButtonShown?.(false);
      }
    } else if (props.items) {
      setRows(
        props.items.slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        )
      );
      setTotalRows(props.items.length);
      if (
        props.items.length != 0 ||
        (props?.appliedFilters &&
          Object.entries(props?.appliedFilters).length !== 0)
      ) {
        props.setIsFilterButtonShown?.(true);
      } else {
        props?.setIsFilterButtonShown?.(false);
      }
    }
  }, [paginatedData, page, rowsPerPage, props?.items]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (rows.length != 0 && !isLoading) ||
    !props?.handleEmptyTable ||
    Object.entries(props?.appliedFilters).length !== 0 ? (
    <TableContainerStyled isClient={props?.isClient}>
      <TableWrapper>
        <TableComponent>
          <TableHeader
            t={props.t}
            tableAlignmentLeft={props.tableAlignmentLeft}
            tableHeaders={props.tableHeaders}
          />
          <TableBodyContainer
            rows={rows}
            tableHeaders={props.tableHeaders}
            rowBtnClick={props.rowBtnClick}
            deleteBtnClick={props?.deleteBtnClick}
            tableAlignmentLeft={props.tableAlignmentLeft}
            isSuperAdmin={props?.isSuperAdmin}
            numberOfCharactersPerCell={props?.numberOfCharactersPerCell}
            halfSizeHeight={props?.halfSizeHeight}
            newNotificationsCount={props?.newNotificationsCount}
          />
        </TableComponent>
      </TableWrapper>
      <TableFooterWrapper>
        <TableFooterControls
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          startingNumberOfItemsPerPage={props?.startingNumberOfItemsPerPage}
          incrementNumberOfItemsPerPage={props?.incrementNumberOfItemsPerPage}
          t={props.t}
        />
      </TableFooterWrapper>
    </TableContainerStyled>
  ) : (
    !isLoading ?? props?.handleEmptyTable?.()
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.any,
  usePaginatedQuery: PropTypes.func,
  fetchFunction: PropTypes.func,
  t: PropTypes.any,
  tableFor: PropTypes.any,
  mapRowDataFunction: PropTypes.func,
  theme: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  appliedFilters: PropTypes.object,
  isClient: PropTypes.bool,
  clientId: PropTypes.any,
  userId: PropTypes.any,
  serviceCategoryId: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
  tableAlignmentLeft: PropTypes.bool,
  numberOfCharactersPerCell: PropTypes.number,
  handleEmptyTable: PropTypes.func,
  setIsFilterButtonShown: PropTypes.func,
  items: PropTypes.array,
  startingNumberOfItemsPerPage: PropTypes.number,
  incrementNumberOfItemsPerPage: PropTypes.number,
  halfSizeHeight: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  newNotificationsCount: PropTypes.number,
};

Table.defaultProps = {
  mapRowDataFunction: (d) => d,
  tableAlignmentLeft: false,
  rowsPerPage: 10,
  newNotificationsCount: 0,
  startingNumberOfItemsPerPage: 10,
};

export default Table;
