export const clientFormikParams = {
  registrationName: "registrationName",
  countryPhoneCode: "countryPhoneCode",
  phoneNumber: "phoneNumber",
  description: "description",
  city: "city",
  country: "country",
  webAddress: "webAddress",
  address: "address",
  primaryServiceCategoryName: "primaryServiceCategoryName",
  primaryServiceCategoryId: "primaryServiceCategoryId",
  workingDaysHours: "workingDaysHours",
  appointmentStartTimeInMinutes: "appointmentStartTimeInMinutes",
  publicAppointmentEnabled: "publicAppointmentEnabled",
  timeBeforeCancellationAllowedInHours: "timeBeforeCancellationAllowedInHours",
};
