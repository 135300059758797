import { LanguageCodes } from "constants/languages";

export const primaryThemeFonts = {
  [LanguageCodes.EN]: {
    buttonFontSize: "12px",
  },
  [LanguageCodes.SR]: {
    buttonFontSize: "16px",
  },
};
