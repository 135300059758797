import styled from "styled-components";

export const HorizontalSlideContainer = styled.div`
  overflow: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 100%;
`;
