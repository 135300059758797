import styled from "styled-components";

export const TimeSlotWrapper = styled.div`
  background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  color: ${(props) => props?.theme?.colors?.timeSlotText};
  border-radius: 8px;
  padding: 5px;
  margin: 0px 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  img {
    cursor: pointer;
    margin-left: 5px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
`;
