import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import { StyledSelect } from "./TypeDropdownField.styled";
import PropTypes from "prop-types";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { Box } from "@mui/material";
import { licenseTypes } from "constants/licenceType";

const TypeDropdownField = (props) => {
  const { t } = useTranslation();

  const translatedLicenseTypes = licenseTypes.map((type) => ({
    ...type,
    displayName: t(type.name),
  }));

  const isError =
    props?.formik?.touched[props.name] &&
    !!props?.formik?.errors[props.name] &&
    !props?.value;

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <FormControl error={isError} fullWidth>
        <StyledSelect
          name={props.name}
          value={props.value || ""}
          onChange={(event) => {
            props.onChange(event);
            props.formik.handleChange(event);
          }}
          onBlur={props?.formik?.handleBlur}
          displayEmpty
          renderValue={(selected) => {
            if (props.value === "" || props.value === null) {
              return <em>{t(props.placeholder)}</em>;
            }
            const selectedValue = Number(selected);
            const selectedType = translatedLicenseTypes.find(
              (type) => type.id === selectedValue
            );
            return selectedType ? (
              selectedType.displayName
            ) : (
              <em>{t(props.placeholder)}</em>
            );
          }}
        >
          <MenuItem value="" disabled>
            {t(props.placeholder)}
          </MenuItem>
          {translatedLicenseTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.displayName}
            </MenuItem>
          ))}
        </StyledSelect>
        {isError && (
          <FormHelperText>
            {t("superAdmin.table.licenses.licenseTypeRequired")}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

TypeDropdownField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  formik: PropTypes.any.isRequired,
};

TypeDropdownField.defaultProps = {
  label: "superAdmin.table.licenses.selectLicenseType",
  name: "type",
  placeholder: "superAdmin.table.licenses.chooseLicence",
};

export default TypeDropdownField;
