import AppointmentStatus from "components/Appointment/AppointmentStatus/AppointmentStatus";
import CustomTooltip from "components/Appointment/AppointmentsTooltip/AppointmentTooltip";
import CancelRenderer from "components/Appointment/CancelButtonRenderer.js/CancelRenderer";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";
import tooltipConstants from "constants/tooltipConstants";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "appointments.table.date",
    field: "date",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 1,
    headerName: "appointments.table.time",
    field: "time",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 2,
    headerName: "appointments.table.clientName",
    field: "clientName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 3,
    headerName: "appointments.table.serviceName",
    field: "serviceName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 4,
    headerName: "appointments.table.price",
    field: "price",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 5,
    headerName: "clientService.serviceStatus",
    field: "status",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => {
      return (
        <AppointmentStatus
          status={params?.name}
          activeTextKey="appointments.appointmentStatus.reserved"
          inactiveTextKey="appointments.appointmentStatus.cancelled"
          invalidTextKey="appointments.appointmentStatus.done"
          currentActive={
            params?.row?.status === APPOINTMENT_STATUS.RESERVED &&
            new Date(params.row?.startDateTimeAppointment + "Z") < new Date() &&
            new Date(params.row?.endDateTimeAppointment + "Z") > new Date()
          }
        />
      );
    },
  },
  {
    columnId: 6,
    headerName: "",
    field: "cancel",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <CustomTooltip params={params} placement={tooltipConstants.TOP}>
        <span>
          <CancelRenderer
            disabled={
              params?.row?.status === APPOINTMENT_STATUS.CANCELLED ||
              params?.row?.status === APPOINTMENT_STATUS.DONE ||
              (params?.row?.status === APPOINTMENT_STATUS.RESERVED &&
                (new Date(params.row?.startDateTimeAppointment + "Z") -
                  new Date()) /
                  (1000 * 60 * 60) <
                  params?.row?.allowedTime)
            }
            params={params}
            onClick={params?.onClickEdit}
          />
        </span>
      </CustomTooltip>
    ),
  },
];
