import styled from "styled-components";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";

export const StyledTableHead = styled(TableHead)`
  display: flex;
  flex-direction: row;
`;

export const StyledTableRow = styled(TableRow)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 15px;
  min-width: 15%;
  border: none;
`;

export const TableTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledTableRowWorkingHours = styled(TableRow)`
  display: flex;
  align-items: center;
  width: 100%;
`;
