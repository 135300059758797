import React from "react";
import PropType from "prop-types";
import { Spinner, SpinnerOverlay } from "./LoadingSpinner.styled";

const LoadingSpinner = (props) => {
  return (
    <SpinnerOverlay>
      <Spinner halfSize={props?.halfSize} />
    </SpinnerOverlay>
  );
};

LoadingSpinner.propTypes = {
  halfSize: PropType.bool,
};

export default LoadingSpinner;
