import styled from "styled-components";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";

export const StyledTableBody = styled(TableBody)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: ${(props) =>
    props?.setMinHeight ? (props?.halfSizeHeight ? "375px" : "750px") : "0px"};
  padding-right: 20px;
`;

export const StyledTableRow = styled(TableRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) =>
    props?.isNewNotification
      ? props?.theme?.colors?.notificationColor
      : props?.status === APPOINTMENT_STATUS.DONE
      ? props?.theme?.colors?.disabledRow
      : props?.theme?.colors?.rowBackgroundColor};
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 15px;
  width: ${(props) => (props?.singleRow ? "15%" : "auto")};
  border: none;
  display: flex;
  align-items: center;
  & .MuiTypography-root {
    display: flex;
    flex-direction: row;
  }
`;

export const LastCell = styled(StyledTableCell)`
  display: flex;
  justify-content: right;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) =>
    props?.status === APPOINTMENT_STATUS.DONE
      ? props?.theme?.colors?.textColor2
      : props?.theme?.colors?.rowTextColor};
`;

export const StyledTableRowWorkingHours = styled(TableRow)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const LastCellRowWorkingHours = styled(LastCell)`
  margin-left: auto;
`;
