import { PAGES } from "constants/pages";
import { createSelector } from "reselect";

const breadcrumbsSelector = (state) => state;

export const selectBreadcrumbNames = (names) =>
  createSelector(breadcrumbsSelector, (state) => {
    let namesToReturn = [];
    names?.forEach((singleName) => {
      if (singleName === PAGES.CLIENTS_DETAILS.titleToRender) {
        namesToReturn.push(
          state?.clientObject?.client?.fullName ??
            state.clientService?.clientService?.client?.fullName ??
            ""
        );
      } else if (singleName === PAGES.CLIENT_SERVICE.titleToRender) {
        namesToReturn.push(state.clientService?.clientService?.name ?? "");
      } else if (singleName === PAGES.SCHEDULE_APPOINTMENT.titleToRender) {
        namesToReturn.push(state.clientService?.clientService?.name ?? "");
      } else if (singleName === PAGES.USER.titleToRender) {
        namesToReturn.push(state.userObject?.user?.email ?? "");
      }
    });
    return namesToReturn;
  });
