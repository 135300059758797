import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTypography,
  LastCell,
  StyledTableRowWorkingHours,
  LastCellRowWorkingHours,
} from "./TableBodyContainer.styled";
import { TEXTVARIANT } from "constants/textConstants";
import { Tooltip } from "@mui/material";
import tooltipConstants from "constants/tooltipConstants";
import { NoRowsBody } from "components/ClientServiceContent/ClientServiceTable/TableBodyContainer.styled";

const TableBodyContainer = (props) => {
  const RowComponent = props.tableAlignmentLeft
    ? StyledTableRowWorkingHours
    : StyledTableRow;
  const LastCellComponent = props.tableAlignmentLeft
    ? LastCellRowWorkingHours
    : LastCell;

  const getNestedValue = (obj, path) => {
    return path?.split(".").reduce((acc, part) => acc && acc[part], obj);
  };
  return (
    <StyledTableBody
      setMinHeight={props?.setMinHeight}
      halfSizeHeight={props?.halfSizeHeight}
    >
      {props?.rows?.map((row, index) => {
        const isNewNotification = index < props.newNotificationsCount;
        return (
          <RowComponent
            key={row.id}
            status={row?.status}
            isNewNotification={isNewNotification}
          >
            {props.tableHeaders.map((col, colIndex) => {
              let data = getNestedValue(row, col.field);
              const formattedData = col?.valueFormatter?.(data, row) ?? data;
              const isLastColumn = colIndex === props.tableHeaders.length - 1;
              const cellRendered = col?.cellRenderer ? (
                col.cellRenderer({
                  name: formattedData,
                  onClickEdit: () => props.rowBtnClick(row),
                  onClickDelete: () => props?.deleteBtnClick(row.id),
                  row,
                  isSuperAdmin: props?.isSuperAdmin,
                })
              ) : formattedData?.length > props?.numberOfCharactersPerCell ? (
                <Tooltip title={formattedData} placement={tooltipConstants.TOP}>
                  <StyledTypography variant={TEXTVARIANT.H5}>
                    {formattedData.slice(
                      0,
                      props?.numberOfCharactersPerCell - 3
                    ) + "..."}
                  </StyledTypography>
                </Tooltip>
              ) : (
                <StyledTypography status={row?.status} variant={TEXTVARIANT.H5}>
                  {formattedData}
                </StyledTypography>
              );
              return isLastColumn ? (
                <LastCellComponent key={col.field}>
                  {cellRendered}
                </LastCellComponent>
              ) : (
                <StyledTableCell
                  key={col.field}
                  singleRow={props?.rows?.length === 1}
                >
                  {cellRendered}
                </StyledTableCell>
              );
            })}
          </RowComponent>
        );
      })}
      {props?.rows?.length === 0 && <NoRowsBody />}
    </StyledTableBody>
  );
};

TableBodyContainer.propTypes = {
  rows: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  tableHeaders: PropTypes.any,
  theme: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
  tableAlignmentLeft: PropTypes.bool,
  setMinHeight: PropTypes.bool,
  halfSizeHeight: PropTypes.bool,
  numberOfCharactersPerCell: PropTypes.number,
  newNotificationsCount: PropTypes.number,
};

TableBodyContainer.defaultProps = {
  tableAlignmentLeft: false,
  setMinHeight: true,
  newNotificationsCount: 0,
};

export default TableBodyContainer;
