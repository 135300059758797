import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  ChipDeleteIconStyled,
  FilterChip,
  FilterChipsContainer,
} from "./FilterChips.styled";
import { useTranslation } from "react-i18next";

const FilterChips = (props) => {
  const { t } = useTranslation([]);

  useEffect(() => {
    if (!props?.areFiltersShown) {
      props?.saveFilters();
    }
  }, [props?.filters]);

  return (
    <FilterChipsContainer>
      {Object.values(props?.appliedFilters || {})?.map?.((filter) => {
        return (
          filter?.id != null && (
            <FilterChip key={filter.id}>
              {t(filter.name)}
              <ChipDeleteIconStyled
                onClick={() => {
                  props?.removeFilter(filter.valueToRemove);
                }}
              />
            </FilterChip>
          )
        );
      })}
    </FilterChipsContainer>
  );
};

FilterChips.propTypes = {
  filterComponents: PropTypes.array,
  appliedFilters: PropTypes.array,
  filters: PropTypes.array,
  removeFilter: PropTypes.func,
  saveFilters: PropTypes.func,
  areFiltersShown: PropTypes.bool,
};

FilterChips.defaultProps = {};

export default FilterChips;
