import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";
import PropTypes from "prop-types";
import inputTypes from "constants/inputTypes";

const DateField = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props?.value || "");

  const handleChange = (event) => {
    setValue(event.target.value);
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)}></CustomFieldLabel>
      <CustomTextField
        value={value}
        type={props?.dateTime ? inputTypes.DATETIME_LOCAL : inputTypes.DATE}
        name={props.name}
        input
        formik={props.formik}
        onChange={handleChange}
        helperText={
          props?.formik?.touched[props.name] &&
          props?.formik?.errors[props.name]
        }
        error={
          props?.formik?.touched[props.name] &&
          !!props?.formik?.errors[props.name]
        }
      ></CustomTextField>
    </Box>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.any,
  dateTime: PropTypes.bool,
};

DateField.defaultProps = {
  label: "superAdmin.table.licenses.startDate",
  name: "startDate",
};

export default DateField;
