import React from "react";
import { PAGES } from "./pages";
import {
  ControlTableIcon,
  ServicesIcon,
  UsersIcon,
  ClientsIcon,
  ControlTableIconSelected,
  ServicesIconSelected,
  UsersIconSelected,
  ClientsIconSelected,
  HelpIcon,
  HelpIconSelected,
  ContactIcon,
  ContactIconSelected,
  AboutUsIcon,
  AboutUsIconSelected,
  MessageIcon,
  MessageIconSelected,
  AppointmentsIcon,
  AppointmentsIconSelected,
} from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { USER_ROLES } from "./userRoles";
import { MessageWrapper } from "components/Notifications/NotificationBadge.styled";
import NotificationBadge from "components/Notifications/NotificationBadge";
export default [
  {
    path: PAGES.BASE.route,
    title: PAGES.BASE.sidebarTitle,
    icon: <ControlTableIcon />,
    iconSelected: <ControlTableIconSelected />,
    hasRole: [],
  },
  {
    path: PAGES.DASHBOARD.route,
    title: PAGES.DASHBOARD.title,
    icon: <ControlTableIcon />,
    iconSelected: <ControlTableIconSelected />,
    hasRole: [USER_ROLES.SUPER_ADMIN.name],
  },
  {
    path: PAGES.SERVICE_CATEGORIES.route,
    title: PAGES.SERVICE_CATEGORIES.title,
    icon: <ServicesIcon />,
    iconSelected: <ServicesIconSelected />,
    hasRole: [USER_ROLES.SUPER_ADMIN.name],
  },
  {
    path: PAGES.SUPERADMIN_CLIENTS.route,
    title: PAGES.SUPERADMIN_CLIENTS.title,
    icon: <ClientsIcon />,
    iconSelected: <ClientsIconSelected />,
    hasRole: [USER_ROLES.SUPER_ADMIN.name],
  },
  {
    path: PAGES.USERS.route,
    title: PAGES.USERS.title,
    icon: <UsersIcon />,
    iconSelected: <UsersIconSelected />,
    hasRole: [USER_ROLES.SUPER_ADMIN.name],
  },
  {
    path: PAGES.APPOINTMENTS.route,
    title: PAGES.APPOINTMENTS.title,
    icon: <AppointmentsIcon />,
    iconSelected: <AppointmentsIconSelected />,
    hasRole: [
      USER_ROLES.USER.name,
      USER_ROLES.CLIENT_ADMIN.name,
      USER_ROLES.CLIENT_EMPLOYEE.name,
    ],
  },
  {
    path: PAGES.MESSAGES.route,
    title: PAGES.MESSAGES.title,
    icon: <MessageWrapper><MessageIcon/><NotificationBadge/></MessageWrapper>,
    iconSelected: <MessageWrapper><MessageIconSelected/><NotificationBadge/></MessageWrapper>,
    hasRole: [
      USER_ROLES.USER.name,
      USER_ROLES.CLIENT_ADMIN.name,
      USER_ROLES.CLIENT_EMPLOYEE.name,
    ],
  },
  {
    path: PAGES.ABOUT.route,
    title: PAGES.ABOUT.title,
    icon: <AboutUsIcon />,
    iconSelected: <AboutUsIconSelected />,
    hasRole: [],
  },
  {
    path: PAGES.HELP.route,
    title: PAGES.HELP.title,
    icon: <HelpIcon />,
    iconSelected: <HelpIconSelected />,
    hasRole: [],
  },
  {
    path: PAGES.CONTACT.route,
    title: PAGES.CONTACT.title,
    icon: <ContactIcon />,
    iconSelected: <ContactIconSelected />,
    hasRole: [],
  },
];
