import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "clientInfo",
  initialState: {
    dataSaved: false,
    isSubmitting: false,
    description: "",
    status: false,
    workingHours: [],
    appointmentEnabled: false,
    appointmentStartTime: 0,
    cancellationTimeframe: 1,
  },
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    toggleFlag: (state) => {
      state.dataSaved = true;
    },
    resetFlag: (state) => {
      state.dataSaved = false;
    },
    submit: (state) => {
      state.isSubmitting = true;
    },
    resetSubmitFlag: (state) => {
      state.isSubmitting = false;
    },
    editDescription: (state, action) => {
      state.description = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setAppointmentEnabled: (state, action) => {
      state.appointmentEnabled = action.payload;
    },
    setAppointmentStartTime: (state, action) => {
      state.appointmentStartTime = action.payload;
    },
    setCancellationTimeframe: (state, action) => {
      state.cancellationTimeframe = Number(action.payload);
    },
    addWorkingHour(state, action) {
      const { day, startTime, endTime } = action.payload;
      const workingHour = { startTime, endTime };
      const dayIndex = state.workingHours.findIndex(
        (entry) => entry.day === day
      );

      if (dayIndex !== -1) {
        state.workingHours[dayIndex].hours.push(workingHour);
      } else {
        state.workingHours.push({ day, hours: [workingHour] });
      }
    },
    removeWorkingHour(state, action) {
      const { day, startTime, endTime } = action.payload;
      const dayIndex = state.workingHours.findIndex(
        (entry) => entry.day === day
      );
      if (dayIndex !== -1) {
        state.workingHours[dayIndex].hours = state.workingHours[
          dayIndex
        ].hours.filter(
          (hour) => hour.startTime !== startTime || hour.endTime !== endTime
        );
      }
    },
  },
});

export const {
  setClient,
  toggleFlag,
  resetFlag,
  submit,
  resetSubmitFlag,
  editDescription,
  setStatus,
  addWorkingHour,
  removeWorkingHour,
  setAppointmentEnabled,
  setAppointmentStartTime,
  setCancellationTimeframe
} = clientSlice.actions;
export default clientSlice.reducer;
export const selectClient = (state) => state?.clientObject?.client;
export const selectFlag = (state) => state.clientInfo.flag;
export const isDataSaved = (state) => state.clientInfo.dataSaved;
export const isSubmitting = (state) => state.clientInfo.isSubmitting;
export const getDescription = (state) => state.clientInfo.description;
export const getStatus = (state) => state.clientInfo.status;
export const getAppointmentEnabled = (state) => state.clientInfo.appointmentEnabled;
export const getAppointmentStartTime = (state) => state.clientInfo.appointmentStartTime;
export const getCancellationTimeframe = (state) => state.clientInfo.cancellationTimeframe;
export const getWorkingDaysHours = (state) => {
  return state.clientInfo.workingHours
    .map((dayEntry) => {
      return dayEntry.hours.map((hour) => ({
        dayOfWeek: dayEntry.day,
        fromTime: hour.startTime,
        toTime: hour.endTime,
      }));
    })
    .flat();
};
