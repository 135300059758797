import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";

const CancelRenderer = (props) => {
  return (
    <ActionButton
      disabled={props?.disabled}
      isCancel={true}
      value={"common.cancel"}
      onClick={props?.onClick}
    />
  );
};
CancelRenderer.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CancelRenderer;
