import { Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";

const CustomTooltip = ({ params, title, placement, children }) => {
  const { t } = useTranslation();

  const getTooltipMessage = () => {
    const status = params?.row?.status;

    const startDateTime = new Date(
      (params?.row?.startDateTimeAppointment + "Z").toLocaleString()
    );
    const allowedTime = params?.row?.allowedTime || 0;

    const timeDifferenceInHours =
      (startDateTime - new Date()) / (1000 * 60 * 60);

    if (status === APPOINTMENT_STATUS.CANCELLED) {
      return t("appointments.tooltips.cancelled");
    }
    if (status === APPOINTMENT_STATUS.DONE) {
      return t("appointments.tooltips.done");
    }
    if (
      status === APPOINTMENT_STATUS.RESERVED &&
      timeDifferenceInHours < allowedTime
    ) {
      if (startDateTime < new Date()) {
        return t("appointments.tooltips.inProgress");
      }
      return t("appointments.tooltips.allowedTimeExceeded", {
        allowedTime,
      });
    }
    return "";
  };

  const tooltipTitle = title || getTooltipMessage();

  return (
    <Tooltip title={tooltipTitle} placement={placement}>
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  params: PropTypes.object,
  title: PropTypes.string,
  placement: PropTypes.any,
  children: PropTypes.node,
};

export default CustomTooltip;
