import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  z-index: 1001;
`;
