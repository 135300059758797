import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ClientAdminCheckContainer,
  GalleryContainer,
  LeftContainer,
  MainContainer,
  RightContainer,
  ServicesContainer,
} from "./ClientPanelForUsers.styled";
import ClientInfoContent from "./ClientInfoContent/ClientInfoContent";
import GalleryContent from "./GalleryContent/GalleryContent";
import ServicesContent from "./ServicesContent/ServicesContent";
import { ClientSwitch } from "components/ClientEditComponents/ClientSwitch";
import { PAGES } from "constants/pages";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { replaceInRoute } from "util/routeHelpers";
import { StyledTypography } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { useTranslation } from "react-i18next";
import WithClientPermission from "components/WithPermissions/WithClientPermission/WithClientPermission";

const ClientPanelForUsers = (props) => {
  const [isUserPanelChecked, setPanelCheck] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUserPanelChange = (event) => {
    setPanelCheck(event.target.checked);

    const pathParams = {
      clientId: props?.client?.id,
    };
    const names = {
      clientId: props?.client?.fullName,
    };

    navigate(
      replaceInRoute(
        PAGES.CLIENTS_DETAILS_INFO.route,
        pathParams,
        names,
        dispatch
      )
    );
  };

  return (
    <MainContainer>
      <LeftContainer>
        <ClientInfoContent client={props.client}></ClientInfoContent>
      </LeftContainer>
      <RightContainer>
        <WithClientPermission clientId={props?.client?.id}>
          <ClientAdminCheckContainer>
            <StyledTypography userPanel>
              {t("superAdmin.table.clients.userPanel")}
            </StyledTypography>
            <ClientSwitch
              checked={isUserPanelChecked}
              onChange={handleUserPanelChange}
            />
          </ClientAdminCheckContainer>
        </WithClientPermission>
        <GalleryContainer>
          <GalleryContent client={props.client}></GalleryContent>
        </GalleryContainer>
        <ServicesContainer>
          <ServicesContent client={props.client}></ServicesContent>
        </ServicesContainer>
      </RightContainer>
    </MainContainer>
  );
};

ClientPanelForUsers.propTypes = {
  client: PropTypes.any,
};

export default ClientPanelForUsers;
