import { createSelector, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userObject",
  initialState: { dataSaved: false },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    toggleFlag: (state) => {
      state.dataSaved = true;
    },
    resetFlag: (state) => {
      state.dataSaved = false;
    },
  },
});

export const { setUser, toggleFlag, resetFlag } = userSlice.actions;

export default userSlice.reducer;

export const userObjectSelector = (state) => state.userObject;
export const isDataSaved = (state) => state.userObject.dataSaved;

export const selectUser = createSelector(
  userObjectSelector,
  (state) => state.user
);
